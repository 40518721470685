import { Link } from "react-router-dom";
import BrandLogo from "../utilities/BrandLogo";

const Menu = ({ open, setOpen, user }) => {
    return (
      <nav className={`menu ${open ? 'open' : ''}`}>
        <div className="_navbar_links for_slider _bg_neutral_content">
          <div className="_navbar_brand">
            <Link to="/">
              <BrandLogo fill="#005B4C" w={145} h={58}/>
            </Link>
            <label onClick={() => setOpen(!open)} className="btn close-btn">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" height={24} viewBox="0 0 24 24" width={24}>
                <path d="m18 6-12 12m0-12 12 12" stroke="#005B4C" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
              </svg>
            </label>
          </div>
          {
              user && user?.name ?
              <Link onClick={() => setOpen(!open)} className="_navbar_link _navbar_item" to="/account">
                Account
              </Link>
             :
              null
          }
          <Link onClick={() => setOpen(!open)} className="_navbar_link _navbar_item" to="/why-snore">
            Why you snore?
          </Link>
          <Link onClick={() => setOpen(!open)} className="_navbar_link _navbar_item" to="/shop">
            Shop
          </Link>
          <Link onClick={() => setOpen(!open)} className="_navbar_link _navbar_item" to="/faq-s">
            FAQs
          </Link>
          {/* <div className="_navbar_link  _navbar_link_drop">
            <span className="_drop_for__desktop">Our Services
              <svg xmlns="http://www.w3.org/2000/svg" className="_navbar_link-arr" width={13} height={8} viewBox="0 0 13 8" fill="none">
                <path d="M0.578125 1.21924L6.34673 6.78056L12.288 1.21924" stroke="black" strokeLinecap="round" />
              </svg>
            </span>
            <label htmlFor="showDrop01" className="_drop_for__mobile _navbar_item">Cloud Services
              <svg xmlns="http://www.w3.org/2000/svg" className="_navbar_link-arr" width={13} height={8} viewBox="0 0 13 8" fill="none">
                <path d="M0.578125 1.21924L6.34673 6.78056L12.288 1.21924" stroke="black" strokeLinecap="round" />
              </svg>
            </label>
            <ul className="_drop__menu _bg_neutral_content">
              <li className="_drop__item">
                <a className="_drop__link" href="https://www.xops.sh/cloud-services">
                  Cloud Services
                </a>
              </li>
              <li className="_drop__item">
                <a className="_drop__link" href="https://www.xops.sh/managed-devops">
                  Managed DevOps Services
                </a>
              </li>
              <li className="_drop__item">
                <a className="_drop__link" href="https://www.xops.sh/kubernetes">
                  Kubernetes Services
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>
    );
  };

  export default Menu;