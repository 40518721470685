import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ReturnPolicies = () => {

    const [policies, setPolicies] = useState([]);


    let privacyPolicies = useSelector(state => state?.returnPolicies?.data?.data_collection?.categories)

    useEffect(() => {
        if(privacyPolicies) {
            setPolicies(privacyPolicies)
        }
    }, [privacyPolicies])
    

    return (
        <>
            <section className="_privacy_policies">
                <div className="_privacy_policies_wrapper _container">
                    <div className="_privacy_policies_head">
                        <h1 className="_privacy_policies_headline _h2">
                            SnoreQuit Privacy Policy
                        </h1>
                        <p className="_privacy_policies_desc">
                            <span>
                            At SnoreQuit Solutions Pvt. Ltd., we strive to provide high-quality
                            snoring stop devices to help you achieve better sleep. If you are
                            not completely satisfied with your purchase, our return policy
                            provides guidelines for returning products and receiving refunds
                            or exchanges.
                            </span>
                            <span>
                            How to return your snoring stop device and get a refund:
                            </span>
                        </p>
                    </div>
                    <div className="_privacy_policies_body">
                            {
                                policies && policies.map((policy, index) => (
                                    <div className="_privacy_policies_content" key={index}>
                                        {
                                            policy.title ?
                                            <h6 className="_privacy_policies_title">
                                                {policy.title}
                                            </h6>
                                            : null
                                        }
                                        {
                                            policy.description || policy.examples ?
                                            <p className="_privacy_policies_typo">
                                                {
                                                    policy.description ?
                                                    <span>
                                                        {policy.description}
                                                    </span>
                                                    :
                                                    null
                                                }

                                                {
                                                    policy.examples ? 
                                                    <ul>
                                                        {
                                                            policy.examples.map((listItem, listIndex) => (
                                                                <li key={listIndex}>
                                                                    {listItem}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                    : null
                                                }
                                            </p>
                                            : null
                                        }

                                    </div>
                                ))
                            }
                        </div>

                    </div>
            </section>
        </>
    );
}
 
export default ReturnPolicies;