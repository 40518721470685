import { FETCH_CART_FAILURE, FETCH_CART_REQUEST, FETCH_CART_SUCCESS } from "../../actions/actionTypes";

const fetchCart = (state = { data: null, loading: false, error: null }, action) => {
  switch (action.type) {
    case FETCH_CART_REQUEST:
      return { ...state, loading: true };
    case FETCH_CART_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case FETCH_CART_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default fetchCart;