import React, { useEffect, useState } from 'react';
import axios from 'axios';

import AmazonBuyButton from "./utilities/AmazonBuyButton";
import FlipkartBuyButton from "./utilities/FlipkartBuyButton";
import MediaCard from "./utilities/MediaCard";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import baseAPI from '../API/baseAPI';
import endPoints from '../API/endPoints';
import { addToCart } from '../redux/actions/cart';
import useCart from './utilities/useCart';
import { useSelector } from 'react-redux';


const ProductOverview = ({product, productFeatures}) => {

    const [usage, setUsage] = useState([]);
    const [thumbnail, setThumbnail] = useState([]);

    // const dispatch = useDispatch();
    const { addItemToCart } = useCart();

    const cartItems = useSelector(state => state?.cartDetails?.data);

    const handleAddToCart = (cartId, productCode, productName, quantity, price) => {
        if(cartItems[0]?.cartId) {
            let cartId = cartItems[0]?.cartId;
            addItemToCart(cartId, product.productCode, product.productName, 1, product.currentPrice);
        } else {
            console.log("cart id not created")
        }
    };
    
    useEffect(() => {
      const fetchImages = async () => {
        try {
          const response = await baseAPI.get(`/${endPoints.AWS.fetchUsageImagesForProduct}`,{
            params: {
              relatedProductCode: product.productCode
            }
          }); // Adjust the URL as necessary
          setUsage(response.data);
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };
  
      fetchImages();
    }, [product]);
  
    useEffect(() => {
      const fetchImages = async () => {
        try {
          const response = await baseAPI.get(`/${endPoints.AWS.fetchProductThumbnails}`,{
            params: {
              relatedProductCode: product.productCode
            }
          }); // Adjust the URL as necessary
          setThumbnail(response.data);
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };
  
      fetchImages();
    }, [product]);

    // for image and desc compare
    const getImageTitleFromUrl = (url) => {
        const parts = url.split('/');
        const fileName = parts[parts.length - 1];
        return fileName.split('.')[0]; // Extracts "PSQ01-U1" from "PSQ01-U1.png"
    };


    return (
        <>
            <section className="_product-overview">
                <div className="_container _product-overview_wrapper">
                    {/* <div className="_product-overview_breadcrumb">Home / <Link className="_brand_logo" to='/'>Shop All Products</Link> / {product.name} </div> */}
                    <div className="_product-overview_body">
                        <div className="_product-overview_media">
                            <Splide 
                                options={{
                                    type: "loop",
                                    gap: "30px",
                                    drag: "free",
                                    arrows: false,
                                    focus: 'center',
                                    pagination: true,
                                    autoplay:true,
                                    interval     : 2500,
                                    resetProgress: true,
                                    breakpoints: {
                                        6400: {
                                            perPage: 1,
                                        },
                                    },
                                    autoScroll: {
                                        pauseOnHover: false,
                                        pauseOnFocus: false,
                                        rewind: true,
                                        speed: 0.8,
                                    },
                                }}
                                // extensions={{ autoScroll }}
                            >
                                {thumbnail.map((item, index) => 
                                    !item.endsWith('/') && (
                                        <SplideSlide>
                                            <img src={item} alt={`S3 Image ${index}`} />                        
                                        </SplideSlide>
                                    )
                                )}
                            </Splide>
                        </div>
                        <div className="_product-overview_info">
                            <h1 className="_h2 _product-overview_title">{product.productName}</h1>
                            <div className="_product-overview_desc">
                                <p className="_product-overview_desc_typo">
                                    Description
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="18" height="18" 
                                        viewBox="0 0 26 26" fill="none"
                                        style={{transform:'rotate(180deg)'}}
                                    >
                                        <path d="M4.5 16.6548L12 9.15479L19.5 16.6548" stroke="#515151" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </p>
                                <div className="_product-overview_desc_detail">
                                    {productFeatures?.desc?.desc ? <p className="_product-overview_desc_text">{productFeatures.desc.desc}</p> : ''}
                                    {productFeatures?.desc?.key_points ? <>
                                        <ul className="_product-overview_desc_key-points" style={{paddingBottom:'25px'}}>
                                            {
                                              productFeatures?.desc?.key_points && productFeatures?.desc?.key_points.map((list, index) => (
                                                    <li className="_product-overview_desc_key-point" key={index}>
                                                        {list?.title ? <span className="_product-overview_desc_key-point_title">{list.title} - &nbsp;</span> : ""}
                                                        
                                                        {list?.desc ? <p>{list.desc}</p> : ""}
                                                    </li>
                                              ))  
                                            }
                                        </ul>
                                    </>: ""}
                                    {productFeatures?.desc?.footer_desc !== 'NA' ? <p className="_product-overview_desc_footer_text">{productFeatures?.desc?.footer_desc}</p> : ''}
                                </div>
                            </div>
                            <div className="_product-overview_price">
                                <span className="_product-overview_price_mrp">{product.defaultPrice}</span>
                                <span className="_product-overview_price_offer">₹<span>{product.currentPrice}</span></span>
                            </div>
                            <div className="_product-overview_buttons">
                                <FlipkartBuyButton size="_is_large" handleAddToCart={handleAddToCart}/>
                                <AmazonBuyButton size="_is_large"/>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                    {
                        product?.shortDescription !== "Single pack product" 
                        ? 
                            <div className="_product-overview_why-sizes">
                                <h6 className="_product-overview_why-sizes_title">
                                    Why we offer 2 sizes
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="18" height="18" 
                                        viewBox="0 0 26 26" fill="none"
                                        style={{transform:'rotate(180deg)'}}
                                    >
                                        <path d="M4.5 16.6548L12 9.15479L19.5 16.6548" stroke="#515151" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </h6>
                                <ul className="_product-overview_why-sizes_key-points">
                                    <li className="_product-overview_why-sizes_key-point">
                                        <span className="_product-overview_why-sizes_key-point_title">Size 1:</span>
                                        <span className="_product-overview_why-sizes_key-point_desc">Provides a slight jaw advancement (+2mm).</span>
                                    </li>
                                    <li className="_product-overview_why-sizes_key-point">
                                        <span className="_product-overview_why-sizes_key-point_title">Size 2:</span>
                                        <span className="_product-overview_why-sizes_key-point_desc">Offers a slightly greater jaw advancement (+6mm). Test both to determine which effectively stops your snoring.</span>
                                    </li>
                                </ul>
                            </div>
                        :
                        null
                    }
                    {
                        productFeatures ?
                        <>
                            {
                                usage.length > 0 ?
                                    <div className="_product-overview_media_key-points">
                                        {usage.map((item, index) => {

                                            return (
                                                !item.endsWith('/') && (
                                                    <MediaCard key={index} page="_product-detail" img={item} />
                                                )
                                            );
                                        })}
                                    </div>
                                :
                                null
                            }
                            <div className="_product-overview_buttons_on-bottom">
                                <FlipkartBuyButton size="_is_medium"/>
                                <AmazonBuyButton size="_is_medium"/>
                            </div>
                        </>
                        :
                        null
                    }
                </div>
            </section>
        </>
    );
}
 
export default ProductOverview;