import OrderHistoryCard from "../components/OrderHistoryCard";

const OrderInfo = () => {
    return (
        <>
            <section className="_order_info">
                <div className="_order_info_status">
                    Order Status: In-transit
                </div>
                <div className="_order_info_wrapper _container">
                    <div className="_order_info_basic">
                        <span>Order ID: 000111</span>
                        <figure>
                            <img src="https://sqspapps3.s3.ap-south-1.amazonaws.com/assets/media/images/products/PSQ01/thumbnail/PSQ01-T1.png"/>
                        </figure>
                        <h4 className="_order_info_basic_title">SnoreQuit Anti-Snoring Mouthpiece
                        - 2 size pack</h4>
                    </div>
                    <div className="_order_info_more">
                        <span className="_order_info_price">$68.00</span>
                        <div className="_order_info_quantity">
                            <span>Quantity</span>
                            <span>1</span>
                        </div>
                    </div>
                    <div className="_order_info_buttons">
                        <button className="_order_info_button _button _is_large _is_primary">Track order</button>
                        <button className="_button _is_outline _is_small _order_info_button">Product Info</button>
                        <button className="_button _is_outline _is_small _order_info_button">Invoice</button>
                        <button className="_order_info_button _button _is_large _is_disabled">Return</button>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default OrderInfo;