import { PRIVACY_POLICIES_DETAIL_FAILURE, PRIVACY_POLICIES_DETAIL_REQUEST, PRIVACY_POLICIES_DETAIL_SUCCESS } from "./actionTypes";
import endPoints from "../../API/endPoints"
import baseAPI from "../../API/baseAPI";

export const fetchPrivacyPoliciesRequest = () => ({
    type: PRIVACY_POLICIES_DETAIL_REQUEST,
});
  
export const fetchPrivacyPoliciesSuccess = (data) => ({
    type: PRIVACY_POLICIES_DETAIL_SUCCESS,
    payload: data,
});
  
export const fetchPrivacyPoliciesFailure = (error) => ({
    type: PRIVACY_POLICIES_DETAIL_FAILURE,
    payload: error,
});

export const fetchPrivacyPolicies = () => {
    return async (dispatch) => {
        dispatch(fetchPrivacyPoliciesRequest());
        try {
            const response = await baseAPI.get(`/${endPoints.PRIVACY_POLICIES.fetchprivacyPolicies}`);
            dispatch(fetchPrivacyPoliciesSuccess(response.data));
        } catch (error) {
            dispatch(fetchPrivacyPoliciesFailure(error.message));
        }
    };
};

