// cartReducer.js
import { toast } from 'react-toastify';
import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_CART_QUANTITY, CLEAR_CART } from '../actions/actionTypes';

const initialState = {
  items: []
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
        const existingItem = state.items.find(item => item.productId === action.payload.productId);
        if (existingItem) {
            toast.success('Already in Cart, Qty Updated')
          return {
            ...state,
            items: state.items.map(item =>
              item.productId === action.payload.productId
                ? { ...item, quantity: item.quantity + 1 }
                : item
            )
          };
        } else {
        toast.success('Successfuly added to Cart')
          return {
            ...state,
            items: [...state.items, { ...action.payload, quantity: 1 }]
          };
        }
    
    case REMOVE_FROM_CART:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload)
      };
    
    case UPDATE_CART_QUANTITY:
        const { productId, quantity } = action.payload;
        console.log(productId, quantity)
        if (quantity <= 0) {
            return {
              ...state,
              items: state.items.filter(item => item.productId !== productId)
            };
        } else {
            return {
              ...state,
              items: state.items.map(item =>
                item.productId === productId
                  ? { ...item, quantity }
                  : item
              )
            };
        }
    
    case CLEAR_CART:
      return {
        ...state,
        items: []
      };
    
    default:
      return state;
  }
};

export default cartReducer;
