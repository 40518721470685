import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PrivacyPolicies = () => {

    const [policies, setPolicies] = useState([]);


    let privacyPolicies = useSelector(state => state?.privacyPolicies?.data?.data_collection?.categories)

    useEffect(() => {
        if(privacyPolicies) {
            setPolicies(privacyPolicies)
        }
    }, [privacyPolicies])
    

    return (
        <>
            <section className="_privacy_policies">
                <div className="_privacy_policies_wrapper _container">
                    <h1 className="_privacy_policies_headline _h2">
                        SnoreQuit Privacy Policy
                    </h1>
                    <div className="_privacy_policies_body">
                            {
                                policies && policies.map((policy, index) => (
                                    <div className="_privacy_policies_content" key={index}>

                                        {
                                            policy.title ?
                                            <h6 className="_privacy_policies_title">
                                                {policy.title}
                                            </h6>
                                            : null
                                        }
                                        {
                                            policy.description || policy.examples ?
                                            <p className="_privacy_policies_typo">
                                                {
                                                    policy.description ?
                                                    <span>
                                                        {policy.description}
                                                    </span>
                                                    :
                                                    null
                                                }
                                                {
                                                    policy.examples ? 
                                                    <ul>
                                                        {
                                                            policy.examples.map((listItem, listIndex) => (
                                                                <li key={listIndex}>
                                                                    {listItem}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                    : null
                                                }
                                            </p>
                                            : null
                                        }

                                    </div>
                                ))
                            }
                        </div>

                    </div>
            </section>
        </>
    );
}
 
export default PrivacyPolicies;