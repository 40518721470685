import FAQFeature from "../components/sections/FAQFeature";
import FAQsSection from "../components/sections/FAQsSection";

const FAQsPage = () => {
    return (
        <>
            <FAQFeature/>
            <FAQsSection/>
        </>
    );
}
 
export default FAQsPage;