import { useState } from "react";
import SubscribeForm from "../utilities/tabs/forms/SubscribeForm";
import { toast } from "react-toastify";
import baseAPI from "../../API/baseAPI";
import endPoints from "../../API/endPoints";

const SleepSolutionsDownloadSubscribe = ({page = undefined}) => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            setMessage('Email is required.');
            toast.error("Email is required.");
            return;
        }

        if (!validateEmail(email)) {
            setMessage('Invalid email address.');
            toast.error("Invalid email address.");
            return;
        }


        try {
            const response = await baseAPI.post(`/${endPoints.SUBSCRIBE.postSubscribeUser}`, { email });;
            toast.success(response)
            setEmail('')
            } catch (error) {
            toast.error(error.response.data)
            setEmail('')
        }
    };

    return (
        <>
            <section className={`_sleep_solutions_download_subscribe ${page == undefined ? '' : page }`}>
                <div className="_sleep_solutions_download_subscribe_wrapper _container">
                    <div className="_sleep_solutions_download_subscribe_media">
                        <img src="./assets/imgs/index/subscribe_01.jpeg"/>
                    </div>
                    <div className="_sleep_solutions_download_subscribe_body">
                        <div className="_sleep_solutions_download_subscribe_info">
                            <h6 className="_sleep_solutions_download_subscribe_info_title">
                            Download for free
                            </h6>
                            <p className="_sleep_solutions_download_subscribe_info_text">
                            25 solutions for better sleep
                            </p>
                        </div>
                        <SubscribeForm
                            wrapperClass="_sleep_solutions_download_subscribe_form_wrapper"
                            labelClass="_sleep_solutions_download_subscribe_form_label"
                            formClass="_sleep_solutions_download_subscribe_form"
                            inputClass="_sleep_solutions_download_subscribe_form_input"
                            buttonClass="_sleep_solutions_download_subscribe_form_button _button _is_large _is_primary"
                            labelText="Enter your email below for your free e-book!"
                            placeholderText="Enter email"
                            buttonText="Subscribe"
                        />
                    </div>
                </div>
                <div className="_sleep_solutions_download_subscribe_wrapper _container _responsive">
                    <h6 className="_sleep_solutions_download_subscribe_info_title">
                        Download for free
                    </h6>
                    <div className="_sleep_solutions_download_subscribe_card">
                        <div className="_sleep_solutions_download_subscribe_media">
                            <img src="./assets/imgs/index/subscribe_01.jpeg"/>
                        </div>
                        <div className="_sleep_solutions_download_subscribe_info">
                            <p className="_sleep_solutions_download_subscribe_info_text">
                                25 solutions for better sleep
                            </p>
                            <p className="_sleep_solutions_download_subscribe_info_typo">
                                Download free SnoreQuit e-book to help you improve your sleep.
                            </p>
                        </div>
                    </div>
                    <div className="_sleep_solutions_download_subscribe_form_wrapper">
                        <label className="_sleep_solutions_download_subscribe_form_label">
                        Enter your email below for your free e-book!
                        </label>
                        <form className="_sleep_solutions_download_subscribe_form" onSubmit={handleSubmit}>
                            <input 
                                className="_sleep_solutions_download_subscribe_form_input"
                                placeholder="Enter email"
                                value={email}
                                onChange={handleInputChange}
                            />
                            
                            <button className="_sleep_solutions_download_subscribe_form_button _button _is_large _is_primary">
                                Subscribe
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default SleepSolutionsDownloadSubscribe;