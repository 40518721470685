// global api endpoints
export default {

  // Product Setup -> Basic Details
  PRODUCT_BASIC_DETAILS: {
    fetchProductDetails: "get-products",
    fetchProductContent: "get-products/content"
  },
  FAQ_ENTRIES: {
    fetchFAQentries:"faq-entries"
  },
  TAB_BASIC_DETAILS: {
    fetchTabDetails: "tabs-info", 
  },
  PRIVACY_POLICIES: {
    fetchprivacyPolicies: "privacy-policies", 
  },
  RETURN_POLICIES: {
    fetchReturnPolicies: "return-policies", 
  },
  SUBSCRIBE: {
    postSubscribeUser: "post-subscriber"
  },
  REVIEWS: {
    fetchReviews: "reviews"
  },
  AWS: {
    fetchUsageImagesForProduct: "listS3Objects-usage",
    fetchProductThumbnails: "listS3Objects-thumbnail"
  },
  AUTH: {
    signup:"add-user",
    login:"users",
    checkTokenExpiration:"protected"
  },
  USER: {
    updateUserBasicDetails:"update-user-detail",
    address:"address",
    updateAddress:"update-address",
    addAddress:"add-address"
  },
  CART: {
    createCart:"cart",
    fetchCart:"cart",
    addItemToCart:"cart"
  }
};
