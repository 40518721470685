import { FETCH_REVIEWS_FAILURE, FETCH_REVIEWS_REQUEST, FETCH_REVIEWS_SUCCESS } from "./actionTypes";
import endPoints from "../../API/endPoints"
import baseAPI from "../../API/baseAPI";

export const fetchReviewsRequest = () => ({
    type: FETCH_REVIEWS_REQUEST,
});
  
export const fetchReviewsSuccess = (data) => ({
    type: FETCH_REVIEWS_SUCCESS,
    payload: data,
});
  
export const fetchReviewsFailure = (error) => ({
    type: FETCH_REVIEWS_FAILURE,
    payload: error,
});

export const fetchReviews = () => {
    return async (dispatch) => {
        dispatch(fetchReviewsRequest());
        try {
            const response = await baseAPI.get(`/${endPoints.REVIEWS.fetchReviews}`);
            dispatch(fetchReviewsSuccess(response.data));
        } catch (error) {
            dispatch(fetchReviewsFailure(error.message));
        }
    };
};

