const initialState = {
    combinedData: []
  };
  
  const fetchAndCombineProductsData = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_COMBINED_DATA':
        return {
          ...state,
          combinedData: action.payload
        };
      default:
        return state;
    }
  };
  
export default fetchAndCombineProductsData;
  