import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import baseAPI from "../API/baseAPI";
import endPoints from "../API/endPoints";
import { toast } from "react-toastify";
import { fetchAddress } from "../redux/actions/fetchAddress";

export const validationRules = {
    name: {
        minLength: 4,
        maxLength: 18,
        regex: /^[a-zA-Z\s]+$/
    },
    number: {
        minLength: 10,
        maxLength: 10,
        regex: /^[0-9]+$/,
    },
    email: {
        minLength: 5,
        maxLength: 25,
        regex: /^(([^<>()\[\]\\.,;:#$%&*\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    country: {
        minLength: 8,
        maxLength: 15,
        regex: /^[a-zA-Z\s]+$/
    },
    address: {
        minLength: 8,
        maxLength: 50,
        regex: /^[a-zA-Z0-9\s!@#\$%\^\&*\)\(+=._-]+$/
    },
    city: {
        minLength: 8,
        maxLength: 15,
        regex: /^[a-zA-Z\s]+$/
    },
    state: {
        minLength: 8,
        maxLength: 15,
        regex: /^[a-zA-Z\s]+$/
    },
    pinCode: {
        minLength: 6,
        maxLength: 6,
        regex: /^[0-9]+$/,
    }
};

const ShippingAddForm = ({setActiveStep, activeStep}) => {

    // Valdation
    const [errors, setErrors] = useState({});

    // Valdation
    const [errorsNewForm, setErrorsNewForm] = useState({
        addressType:"",
        name:"",
        email:"",
        number:"",
        country:"",
        address:"",
        state:"",
        city:"",
        pinCode:""
    });

    const validateForm = (updatedAddress) => {
        const newErrors = {};
        if (!updatedAddress.address) newErrors.address = 'Address is required';
        if (!updatedAddress.addressType) newErrors.addressType = 'Address type is required';
        if (!updatedAddress.city) newErrors.city = 'City is required';
        if (!updatedAddress.country) newErrors.country = 'Country is required';
        if (!updatedAddress.email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(updatedAddress.email)) newErrors.email = 'Valid email is required';
        if (!updatedAddress.phoneNumber || !/^\d{10}$/.test(updatedAddress.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
        if (!updatedAddress.pincode || !/^\d{6}$/.test(updatedAddress.pincode)) newErrors.pincode = 'Valid pincode is required';
        if (!updatedAddress.state) newErrors.state = 'State is required';
        if (!updatedAddress.userId || updatedAddress.userId.length !== 8) newErrors.userId = 'User ID must be 8 characters';
        if (!updatedAddress.userName) newErrors.userName = 'User name is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // selected address with radio button 
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);

    // All Address
    const userAddresses = useSelector(state => state.addresses?.data?.addresses);
    
    // new form show on condition
    const [newFormShow, setNewFormShow] = useState(false)

    // radio address list button onclick
    const handleRadioChange = (addressId) => {
        setNewFormShow(false);
        setSelectedAddressId(addressId);
        let isSelected = userAddresses?.find(address => address?.addressId == addressId);
        setSelectedAddress(isSelected);
    };
    
    // first time render time default address seleted
    useEffect(() => {
        let isDefault = userAddresses?.find(address => address?.isDefault == 1)
        
        if(isDefault) {
            setSelectedAddress(isDefault);
            setSelectedAddressId(isDefault.addressId)
        }
    },[userAddresses])


    // Update Address API
    const updateAddressDetail = async (formData) => {

        const transformedData = {
            ...formData,
            name: formData.userName,
            number: formData.phoneNumber
          };


        try {
            
            let response = await baseAPI.put(`/${endPoints.USER.updateAddress}`, transformedData);
            
            if(response.status == 200) {
                // checkUserToken();
                if (setActiveStep) {
                    setActiveStep(activeStep + 1);
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm(selectedAddress)) {
            updateAddressDetail(selectedAddress)
        }
    };

    const handleChangeUpdateDetail = (e) => {
        const { name, value } = e.target;
        const updatedAddress = {
            ...selectedAddress,
            [name]: value
        };
        setSelectedAddress(updatedAddress);
        validateForm(updatedAddress)
    };


    // for new address ======================================================================
    const inputRefs = useRef({});
    const dispatch = useDispatch();

    const user = useSelector(state => state.token?.data?.user);
    const [userDetails, setUserDetails] = useState({ addressType:"", name: '', number: '', email: '',country:"", address:"",state:"", city:'', zipCode:'',isDefault:"", addressId:'' });
    const [newAdress, setNewAddress] = useState({
        userId:"",
        addressType:false,
        name:"",
        email:"",
        number:"",
        country:"",
        address:"",
        state:"",
        city:"",
        pinCode:"",
        isDefault:false
    });

    const addAddressDetail = async (formData) => {
        let data = {
            "userId": user?.userId,
            "addressType":formData?.addressType,
            "defaultAddressId":userDetails?.addressId ? userDetails?.addressId : '',
            "name":formData?.name,
            "email":formData?.email,
            "number":formData?.number,
            "country":formData?.country,
            "address":formData?.address,
            "state":formData?.state,
            "city":formData?.city,
            "pincode":formData?.pinCode,
            "isDefault":formData?.isDefault
        }
        const config = {
            headers: {
              'Content-Type': 'application/json'
            }
        };

        try {
            let response = await baseAPI.post(`/${endPoints.USER.addAddress}`, data, config);
            if(response.status == 200) {
                toast.success('Succesfuly Address added')
                dispatch(fetchAddress(user?.userId));
                setNewFormShow(true)
                setNewAddress({
                    userId:"",
                    addressType:false,
                    name:"",
                    email:"",
                    number:"",
                    country:"",
                    address:"",
                    state:"",
                    city:"",
                    pinCode:"",
                    isDefault:false
                })                
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const areAllErrorsFalse = () => {
        return Object.values(errorsNewForm).every(error => error === false);
    };
    
    const handleSubmitForNewAddress = (e) => {
        e.preventDefault();
        validateAllFields();
        
        if (areAllErrorsFalse()) {

            addAddressDetail(newAdress)
        } else {
            // Form is not valid, show errors
            // console.log('Form has errors:', errors);
            
            toast.error("Form has errors");
        }
    };
    
    const validateAllFields = () => {
        Object.keys(newAdress).forEach(name => validateField(name, newAdress[name]));
    };

    const handleInput = (e) => {
        const { name, value, checked } = e.target;
        let newValue = value;
        if (name === 'number' || name === 'pinCode') {
            newValue = value.replace(/[^0-9]/g, '');
        } else if (name === "isDefault") {
            newValue = checked
        }
        
        setNewAddress(prev => ({ ...prev, [name]: newValue }));
        validateField(name, newValue);
    };

    const validateField = (name, value) => {
        const rules = validationRules[name];
        if(name == 'addressType') {
            if(value == false) {
                setErrorsNewForm(prev => ({ ...prev, [name]: true }));
            } else {
                setErrorsNewForm(prev => ({ ...prev, [name]: false }));
            }
        }
        if (!rules) return;
        const { minLength, maxLength, regex } = rules;
        let isValid = regex.test(value);

        if (isValid && value.length <= maxLength) {
            setErrorsNewForm(prev => ({ ...prev, [name]: false }));
        } else {
            setErrorsNewForm(prev => ({ ...prev, [name]: true }));
        }
    };

    const renderError = (name) => {
        if (errorsNewForm[name]) {
            switch (name) {
                case 'number':
                    return <div className="_input_error">Invalid phone number</div>;
                case 'email':
                    return <div className="_input_error">Invalid email address</div>;
                case 'pinCode':
                    return <div className="_input_error">Invalid Pin Code</div>;
                case 'addressType':
                    return <div className="_input_error">Invalid Address Type</div>;
                default:
                    return <div className="_input_error">Invalid input</div>;
            }
        }
        return null;
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>  
            <div className="_shipping_detail">
                <h6 className="_sub_h3 _shipping_detail_title">
                    Shipping address
                    {
                        windowWidth > 824 ?
                    <button onClick={() => {setNewFormShow(true)}} className="_button _is_small _is_primary _shipping_detail_button">Add new address</button>    
                    :
                    <span className="_add_icon" style={{cursor:'pointer'}} onClick={() => {setNewFormShow(true)}}>
                        <svg enableBackground="new 0 0 512 512" height="512px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <path d="M256,512C114.625,512,0,397.391,0,256C0,114.609,114.625,0,256,0c141.391,0,256,114.609,256,256  C512,397.391,397.391,512,256,512z M256,64C149.969,64,64,149.969,64,256s85.969,192,192,192c106.047,0,192-85.969,192-192  S362.047,64,256,64z M288,384h-64v-96h-96v-64h96v-96h64v96h96v64h-96V384z" />
                        </svg>
                    </span>
                    }
                </h6>
                {   
                    userAddresses && userAddresses.length > 0 ?
                    <div className="_shipping_detail">
                        <div className="_shipping_detail_address">
                            {
                                userAddresses && userAddresses?.map((address, index) => (
                                <div className="_input_type_radio_item" key={index}>
                                    <input 
                                        id={address.addressId} 
                                        type="radio" 
                                        checked={address.addressId === selectedAddressId && !newFormShow} 
                                        onChange={() => handleRadioChange(address.addressId)} 
                                        name="userAddresses"/>
                                    <label htmlFor={address.addressId}></label>
                                    <label htmlFor={address.addressId} className="_input_type_radio_label" >
                                        {address?.address || address?.phoneNumber ? 
                                            <p>{address?.address} {address?.phoneNumber}</p>
                                            :
                                            null
                                        }
                                    </label>
                                </div>
                                ))
                            }
                        </div>
                    </div>
                    :
                    null
                }
                {
                    userAddresses && userAddresses.length > 0 && selectedAddress && !newFormShow ?

                    <form className="_shipping_detail_form_items" onSubmit={handleSubmit}>
                        <div className="_input_group">
                            <input
                                name="userName"
                                placeholder="Full Name"
                                value={selectedAddress.userName}
                                onChange={handleChangeUpdateDetail}
                            />
                        {errors.userName && <span className="_input_error">{errors.userName}</span>}
                        </div>
                        <div className="_input_group_wrapper">
                            <div className="_input_group_wrapper_row">
                                <div className="_input_group">
                                    <input
                                        name="email"
                                        placeholder="Email"
                                        value={selectedAddress.email}
                                        onChange={handleChangeUpdateDetail}
                                    />
                                    {errors.email && <span className="_input_error">{errors.email}</span>}
                                </div>
                                <div className="_input_group">
                                    <input
                                        name="phoneNumber"
                                        placeholder="Phone number"
                                        value={selectedAddress.phoneNumber}
                                        onChange={handleChangeUpdateDetail}
                                    />
                                    {errors.phoneNumber && <span className="_input_error">{errors.phoneNumber}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="_input_group">
                            <input
                                name="country"
                                placeholder="Country"
                                value={selectedAddress.country}
                                onChange={handleChangeUpdateDetail}
                                />
                                {errors.country && <span className="_input_error">{errors.country}</span>}
                        </div>
                        <div className="_input_group">
                            <input
                                name="address"
                                placeholder="Full Address"
                                value={selectedAddress.address}
                                onChange={handleChangeUpdateDetail}
                                // onChange={handleChange}
                                // className={errors.email ? '_input_errors' : ''}
                                />
                            {errors.address && <span className="_input_error">{errors.address}</span>}

                        </div>
                        <div className="_input_group_wrapper">
                            <div className="_input_group_wrapper_row">
                                <div className="_input_group">
                                    <input
                                        name="city"
                                        placeholder="City"
                                        value={selectedAddress.city}
                                        onChange={handleChangeUpdateDetail}
                                        />
                                    {errors.city && <span className="_input_error">{errors.city}</span>}

                                </div>
                                <div className="_input_group">
                                    <input
                                        name={'state'}
                                        placeholder="State"
                                        value={selectedAddress.state}
                                        onChange={handleChangeUpdateDetail}
                                        />
                                        {errors.state && <span className="_input_error">{errors.state}</span>}
                                </div>
                                <div className="_input_group">
                                    <input
                                        name="pincode"
                                        placeholder="ZIP code"
                                        value={selectedAddress.pincode}
                                        onChange={handleChangeUpdateDetail}
                                    />
                                    {errors.pincode && <span className="_input_error">{errors.pincode}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="_shipping_info_form_links" style={{ justifyContent: `${
                        windowWidth < 824 ? 'center' : ''}` }}>
                        {
                            windowWidth < 824 ? 
                            null
                            :
                            <div className="_input_type_check_item">

                            </div>
                            }
                            <button className="_button _is_primary _shipping_detail_button">
                                Update & continue
                            </button>
                        </div>
                    </form>
                    :
                    <form className="_shipping_detail_form_items"  
                        onSubmit={handleSubmitForNewAddress}
                    >
                     {
                        windowWidth < 824 ?   <h5 style={{fontSize:'18px', fontWeight:'600', fontFamily:"Merriweather"}}>Enter new address</h5> : null }
                        <div className="_input_group_wrapper _input_group_wrapper_radio_error">
                            <div className="_input_group_wrapper_row">
                                <div className="_input_type_radio_item">
                                    <input                                  
                                        id="home"
                                        type="radio"
                                        value="Home"
                                        name="addressType"
                                        onChange={handleInput}
                                        // checked={userDetails?.addressType === 'Home'}
                                        // checked={selectedValue === 'home'}
                                        // onChange={handleChange}
                                    />
                                    <label htmlFor="home"></label>
                                    <label htmlFor="home" className="_input_type_radio_label">
                                        Home
                                    </label>
                                </div>
                                <div className="_input_type_radio_item">
                                    <input
                                        
                                        id="office"
                                        type="radio"
                                        value="Office"
                                        name="addressType"
                                        onChange={handleInput}
                                        // checked={userDetails?.addressType === 'Office'}
                                        // onChange={handleChange}
                                    />
                                    <label htmlFor="office"></label>
                                    <label htmlFor="office" className="_input_type_radio_label">
                                        Office
                                    </label>
                                </div>
                                <div className="_input_type_radio_item">
                                    <input
                                        
                                        id="other"
                                        type="radio"
                                        value="Other"
                                        name="addressType"
                                        onChange={handleInput}
                                        // checked={userDetails?.addressType === 'Other'}
                                        // onChange={handleChange}
                                    />
                                    <label htmlFor="other"></label>
                                    <label htmlFor="other" className="_input_type_radio_label">
                                        Other
                                    </label>
                                </div>
                            </div>
                            {renderError('addressType')}
                        </div>
                        <div className="_input_group">
                            <input
                                placeholder="Full Name"
                                value={newAdress?.name}
                                name="name"
                                onChange={handleInput}
                                className={errorsNewForm.name ? '_has_error' : ''}
                            />
                            {renderError('name')}
                        </div>
                        <div className="_input_group_wrapper">
                            <div className="_input_group_wrapper_row">
                                <div className="_input_group">
                                    <input
                                        placeholder="Email"
                                        value={newAdress?.email}
                                        name="email"
                                        onChange={handleInput}
                                        className={errorsNewForm.email ? '_has_error' : ''}
                                    />
                                    {renderError('email')}
                                </div>
                                <div className="_input_group">
                                    <input
                                        placeholder="Phone Number"
                                        value={newAdress?.number}
                                        name="number"
                                        type="text"
                                        onChange={handleInput}
                                        ref={el => inputRefs.current['number'] = el}
                                        className={errorsNewForm.number ? '_has_error' : ''}
                                    />
                                    {renderError('number')}
                                </div>
                            </div>
                        </div>
                        <div className="_input_group">
                            <input
                                placeholder="Country"
                                value={newAdress?.country}
                                name="country"
                                onChange={handleInput}
                                className={errorsNewForm.country ? '_has_error' : ''}
                            />
                            {renderError('country')}
                        </div>
                        <div className="_input_group">
                            <input
                                placeholder="Full address"
                                value={newAdress?.address}
                                name="address"
                                onChange={handleInput}
                                className={errorsNewForm.address ? '_has_error' : ''}
                            />
                            {renderError('address')}
                        </div>
                        <div className="_input_group_wrapper">
                            <div className="_input_group_wrapper_row">
                                <div className="_input_group">
                                    <input
                                        placeholder="City"
                                        value={newAdress?.city}
                                        name="city"
                                        onChange={handleInput}
                                        className={errorsNewForm.city ? '_has_error' : ''}
                                    />
                                    {renderError('city')}
                                </div>
                                <div className="_input_group">
                                    <input
                                        placeholder="State"
                                        value={newAdress?.state}
                                        name="state"
                                        onChange={handleInput}
                                        className={errorsNewForm.state ? '_has_error' : ''}
                                    />
                                    {renderError('state')}
                                </div>
                                <div className="_input_group">
                                    <input
                                        placeholder="ZIP code"
                                        value={newAdress?.pinCode}
                                        name="pinCode"
                                        onChange={handleInput}
                                        ref={el => inputRefs.current['pinCode'] = el}
                                        className={errorsNewForm.pinCode ? '_has_error' : ''}
                                    />
                                    {renderError('pinCode')}
                                </div>
                            </div>
                        </div>
                        <div className="_shipping_info_form_links">
                            <div className="_input_type_check_item">
                                <input
                                    id="isDefault" 
                                    checked={newAdress?.isDefault} 
                                    name="isDefault" 
                                    onChange={handleInput} 
                                    type="checkbox"
                                />
                                <label className htmlFor="isDefault">
                                    <svg width={14} height={10} viewBox="0 0 14 10">
                                        <path d="M12.829 0.538273C13.1218 0.831167 13.1218 1.30604 12.829 1.59893L5.49562 8.93227C5.20273 9.22516 4.72785 9.22516 4.43496 8.93227L1.10163 5.59893C0.808735 5.30604 0.808735 4.83117 1.10163 4.53827C1.39452 4.24538 1.86939 4.24538 2.16229 4.53827L4.96529 7.34128L11.7683 0.538273C12.0612 0.24538 12.5361 0.24538 12.829 0.538273Z">
                                        </path>
                                    </svg>
                                </label>
                                <label htmlFor="isDefault" className="_input_type_check_label">Make default address</label>
                            </div>
                            <button className="_button _is_primary _shipping_info_form_button">
                                Save Address
                            </button>
                        </div>
                    </form>
                }
            </div>
        </>
    );
}
 
export default ShippingAddForm;