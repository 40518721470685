// SubscribeForm.js
import React, { useState } from 'react';
import baseAPI from '../../../../API/baseAPI';
import endPoints from '../../../../API/endPoints';
import { toast } from 'react-toastify';

const SubscribeForm = ({ wrapperClass, labelClass, formClass, inputClass, buttonClass, labelText, placeholderText, buttonText }) => {
    
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            setMessage('Email is required.');
            toast.error("Email is required.");
            return;
        }

        if (!validateEmail(email)) {
            setMessage('Invalid email address.');
            toast.error("Invalid email address.");
            return;
        }


        try {
            const response = await baseAPI.post(`/${endPoints.SUBSCRIBE.postSubscribeUser}`, { email });;
            toast.success(response)
            setEmail('')
            } catch (error) {
            toast.error(error.response.data)
            setEmail('')
        }
    };

    return (
        <div className={wrapperClass}>
            <label className={labelClass}>{labelText}</label>
            <form className={formClass} onSubmit={handleSubmit}>
                <input 
                    required
                    value={email} 
                    placeholder={placeholderText} 
                    onChange={handleInputChange}
                    className={`${inputClass} ${message === 'Invalid email address.' ? '_input_errors' : ''}`}
                />
                <button className={buttonClass}>{buttonText}</button>
            </form>
        </div>
    );
};

export default SubscribeForm;
