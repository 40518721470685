import { FETCH_ADDRESS_FAILURE, FETCH_ADDRESS_REQUEST, FETCH_ADDRESS_SUCCESS } from "../actions/actionTypes";

const fetchAddress = (state = { data: null, loading: false, error: null }, action) => {
    switch (action.type) {
      case FETCH_ADDRESS_REQUEST:
        return { ...state, loading: true };
      case FETCH_ADDRESS_SUCCESS:
        return { ...state, loading: false, data: action.payload, error: null };
      case FETCH_ADDRESS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
};

export default fetchAddress;