// routes.js
import Auth from '../auth/Auth';
import ProtectedRoute from './ProtectedRoute';
import App from '../App';
import Login from '../pages/Login';
import Account from '../pages/Acccount';
import Policies from '../pages/Policies';
import ReturnPolicies from '../components/sections/ReturnPolicies';
import FAQsPage from '../pages/FAQsPage';
import WhySnore from '../pages/WhySnore';
import AboutUs from '../pages/AboutUs';
import ProductSupport from '../pages/ProductSupport';
import ProductDetail from '../pages/ProductDetail';
import ContactUs from '../pages/ContactUs';
import Shop from '../pages/Shop';
import Cart from '../pages/Cart';
import Payment from '../pages/Payment';
import OrderInfo from '../pages/OrderInfo';
import Index from '../pages/Index';

const routes = [
  {
    path: '/auth',
    element: <Auth />,
    children: [
      { path: 'login', element: <Login /> },
    ],
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'account',
        element: (
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        ),
      },
      {
        path: 'policies',
        element: (
          // <ProtectedRoute>
            <Policies />
          // </ProtectedRoute>
        ),
      },
      {
        path: 'return-policies',
        element: (
          // <ProtectedRoute>
            <ReturnPolicies />
          // </ProtectedRoute>
        ),
      },
      {
        path: 'faq-s',
        element: (
          // <ProtectedRoute>
            <FAQsPage />
          // </ProtectedRoute>
        ),
      },
      {
        path: 'why-snore',
        element: 
          // <ProtectedRoute>
            <WhySnore />
          // </ProtectedRoute>
        ,
      },
      {
        path: 'about-us',
        element: (
          // <ProtectedRoute>
            <AboutUs />
          // </ProtectedRoute>
        ),
      },
      {
        path: 'product-support',
        element: (
          // <ProtectedRoute>
            <ProductSupport />
          // </ProtectedRoute>
        ),
      },
      {
        path: 'product-detail/:pId',
        element: (
          // <ProtectedRoute>
            <ProductDetail />
          // </ProtectedRoute>
        ),
      },
      {
        path: 'contact-us',
        element: (
          // <ProtectedRoute>
            <ContactUs />
          // </ProtectedRoute>
        ),
      },
      {
        path: 'shop',
        element: (
          // <ProtectedRoute>
            <Shop />
          // </ProtectedRoute>
        ),
      },
      {
        path: 'cart',
        element: (
          <ProtectedRoute>
            <Cart />
          </ProtectedRoute>
        ),
      },
      {
        path: 'payment',
        element: (
          <ProtectedRoute>
            <Payment />
          </ProtectedRoute>
        ),
      },
      {
        path: '',
        element: (
          // <ProtectedRoute>
            <Index />
          // </ProtectedRoute>
        ),
      },
      {
        path: 'order-info',
        element: (
          <ProtectedRoute>
            <OrderInfo />
          </ProtectedRoute>
        ),
      }
    ],
  },
];

export default routes;