import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import 'react-toastify/dist/ReactToastify.css';
import useApiCalls from "./components/utilities/useApiCalls";
import ScrollToTopOnRouteChange from "./components/utilities/ScrollToTopOnRouteChange";

function App() {
  // ATBBMsEJakhhadJ2YHsXaTXMSUMP19A1DE9B

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userToken, userId } = useSelector(state => ({
    userToken: state.token?.data?.token,

    userId: state.token?.data?.user?.userId,
  }));
  
  const { data, loading, error } = useSelector((state) => state.productsDetail);

  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  // const checkUserToken = () => {

  //   if (!userToken || userToken === 'undefined' || userToken == null) {
  //     setIsLoggedIn(false);
  //     navigate('/auth');
  //   }
  //   else {
  //     setIsLoggedIn(true);
  //   }
  // };
  // useEffect(() => {
  //   checkUserToken();
  // }, [userToken]);



  useApiCalls(dispatch, userToken, userId);


  if (loading) return (
  <div className="smart-glass">
    <div class="logo">
      <div class="circle">
        <div class="circle">
          <div class="circle">
          </div>
        </div>
      </div>
      <div class="loading-text">
        Loading...
      </div>
    </div>
  </div>
  );
  if (error) return <div>Error: {error}</div>;
  if (!data) return null;
  


  return (
    <>
      {/* {isLoggedIn && <Outlet />} */}
      <ScrollToTopOnRouteChange/>
      <Outlet/>
    </>
  );
}

export default App;
