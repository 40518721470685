import { FETCH_CART_FAILURE, FETCH_CART_REQUEST, FETCH_CART_SUCCESS } from "../actionTypes";

export const fetchCartRequest = () => ({
    type: FETCH_CART_REQUEST,
});
  
export const fetchCartSuccess = (data) => ({
    type: FETCH_CART_SUCCESS,
    payload: data,
});
  
export const fetchCartFailure = (error) => ({
    type: FETCH_CART_FAILURE,
    payload: error,
});

