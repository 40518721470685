// src/components/CartItem.js
import React from 'react';
import axios from 'axios';

const CartItem = ({ item, fetchCart }) => {
  const updateQuantity = async (cartId, cartItemId, quantity) => {
    await axios.put(`http://localhost:8081/cart/${cartId}/items/${cartItemId}`, { quantity });
    fetchCart(cartId);
  };

  const deleteItem = async (cartId, cartItemId) => {
    await axios.delete(`http://localhost:8081/cart/${cartId}/items/${cartItemId}`);
    fetchCart(cartId);
  };

  return (
    <li>
      {item.productName} - {item.quantity} x ${item.price} = ${item.total}
      <input
        type="number"
        value={item.quantity}
        onChange={(e) => updateQuantity(item.cartId, item.cartItemId, e.target.value)}
      />
      <button onClick={() => deleteItem(item.cartId, item.cartItemId)}>Delete</button>
    </li>
  );
};

export default CartItem;
