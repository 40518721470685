import { combineReducers } from 'redux';
import fetchProductDetailReducer from './fetchProductDetail';
import fetchProductContent from './fetchProductsContent';
import fetchFAQentries from './fetchFAQentries';
import fetchTabDetails from './fetchTabDetails';
import fetchDetailedProduct from './fetchDetailedProduct';
import fetchPrivacyPolicies from './fetchPrivacyPolicies';
import fetchReturnPolicies from './fetchReturnPolicies';
import fetchReviews from './fetchReviews';
import authToken from './authToken';
import fetchAddress from './fetchAddress';
import cartReducer from './cartReducer';
import fetchCart from './cart/fetchCart';
import fetchAndCombineProductsData from './fetchAndCombineProductsData';


const rootReducer = combineReducers({
  productsDetail: fetchProductDetailReducer,
  productsContent: fetchProductContent,
  FAQentries: fetchFAQentries,
  tabDetails: fetchTabDetails,
  detailedProducts: fetchDetailedProduct,
  privacyPolicies: fetchPrivacyPolicies,
  returnPolicies: fetchReturnPolicies,
  reviews: fetchReviews,
  token: authToken,
  addresses: fetchAddress,
  cart: cartReducer,
  cartDetails: fetchCart,
  products:fetchAndCombineProductsData
});

export default rootReducer;