import ProductGrid from "../components/sections/ProductGrid";

const Shop = () => {
    return (
        <>
            <ProductGrid page="shop"/>
        </>
    );
}
 
export default Shop;