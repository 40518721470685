import { useState } from "react";
import BrandLogo from "../components/utilities/BrandLogo";
import { toast } from "react-toastify";
import baseAPI from "../API/baseAPI";
import endPoints from "../API/endPoints";
import { useDispatch } from "react-redux";
import { tokenFailure, tokenRequest, tokenSuccess } from "../redux/actions/AuthToken";
import { useNavigate } from "react-router-dom";

import useCart from './utilities/useCart';


const LoginForm = ({openModal}) => {

    const navigate = useNavigate();
    const { createCart } = useCart();
    

    // Validations =========================================
    const [userId, setUserId] = useState("");
    const [userPass, setUserPass] = useState("");
    const [loader, setLoader] = useState(false);
        
    const dispatch = useDispatch();
    const formHandel = (e) => {
        e.preventDefault();

        if(userId === "") {
            toast.error('Please enter Phone Number')
        } else if(userPass === "") {
            toast.error('Please enter Password')
        } else if (userId != "" && userPass != "") {
            validUser();
        } else {
            toast.error("Login Failed")
        }
    }
    
    const validUser = async () => {
        // setLoader(true);
        const data = {
            number: userId,
            password: userPass,
        }
        
        const header = {
            headers: "application/json",
            "Content-Type": "application/json;charset=UTF-8"
        }

        try {
            let response = await baseAPI.post(`/${endPoints.AUTH.login}`, data);
            dispatch(tokenRequest());

            if (response.status === 200 && response.data?.user?.userId) {
                // toast.success("Login Successful");
                createCart(response.data?.user?.userId, 'pending');
                dispatch(tokenSuccess(response.data));
                setUserId('');
                setUserPass('');
                return navigate('/');
            } else {
                toast.error("Please Enter Valid Username or Password");
            }
        }
        catch (error) {
            // setLoader(false);
            toast.error(error.response.data);
            tokenFailure(error.response.data);
            console.log(error);
        }
    }

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };    

    
    return (
        <>
            <div className="_login_exist">
                <p className="_login_exist_title">Welcome back!</p>
                <p className="_login_exist_desc">Already a member? Login here.</p>
                <div>
                    {/* <form className="_contact_us_form_items" onSubmit={handleSubmit}> */}
                    <form className="_login_exist_form_items" name="login" onSubmit={formHandel}>
                        <div>
                            <input
                                value={userId}
                                placeholder="Phone number"
                                onChange={e => {
                                    if (e.target.value.length <= 10) {
                                        setUserId(e.target.value);
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                name="number"
                                // onChange={handleChange}
                                // className={errors.email ? '_input_errors' : ''}
                            />
                        </div>
                        <div style={{position:'relative'}}>
                            <input
                                placeholder="Password"
                                type={showPassword ? 'text' : 'password'}
                                // value={name}
                                name="password"
                                onChange={e => setUserPass(e.target.value)}
                                value={userPass}
                                // onChange={handleChange}
                                // className={errors.name ? '_input_errors' : ''}
                            />
                            {
                                !showPassword ?
                                <span class="_input_field_icon_wrapper _input_field_icon_right _input_password" onClick={togglePasswordVisibility}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">
                                        <path d="M3.11523 13.1699C6.71523 5.16992 17.5152 5.16992 21.1152 13.1699" stroke="#BAA989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.1152 17.1699C11.7213 17.1699 11.3312 17.0923 10.9672 16.9416C10.6032 16.7908 10.2725 16.5698 9.99391 16.2912C9.71534 16.0127 9.49436 15.6819 9.3436 15.318C9.19283 14.954 9.11523 14.5639 9.11523 14.1699C9.11523 13.776 9.19283 13.3858 9.3436 13.0219C9.49436 12.6579 9.71534 12.3272 9.99391 12.0486C10.2725 11.77 10.6032 11.549 10.9672 11.3983C11.3312 11.2475 11.7213 11.1699 12.1152 11.1699C12.9109 11.1699 13.6739 11.486 14.2366 12.0486C14.7992 12.6112 15.1152 13.3743 15.1152 14.1699C15.1152 14.9656 14.7992 15.7286 14.2366 16.2912C13.6739 16.8539 12.9109 17.1699 12.1152 17.1699Z" fill="#BAA989" stroke="#BAA989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <line x1="3.28027" y1="19.9442" x2="19.889" y2="3.33545" stroke="#BAA989" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                </span>
                                :
                                <span class="_input_field_icon_wrapper _input_field_icon_right _input_password" onClick={togglePasswordVisibility}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">
                                        <path d="M3.11523 13.1699C6.71523 5.16992 17.5152 5.16992 21.1152 13.1699" stroke="#BAA989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.1152 17.1699C11.7213 17.1699 11.3312 17.0923 10.9672 16.9416C10.6032 16.7908 10.2725 16.5698 9.99391 16.2912C9.71534 16.0127 9.49436 15.6819 9.3436 15.318C9.19283 14.954 9.11523 14.5639 9.11523 14.1699C9.11523 13.776 9.19283 13.3858 9.3436 13.0219C9.49436 12.6579 9.71534 12.3272 9.99391 12.0486C10.2725 11.77 10.6032 11.549 10.9672 11.3983C11.3312 11.2475 11.7213 11.1699 12.1152 11.1699C12.9109 11.1699 13.6739 11.486 14.2366 12.0486C14.7992 12.6112 15.1152 13.3743 15.1152 14.1699C15.1152 14.9656 14.7992 15.7286 14.2366 16.2912C13.6739 16.8539 12.9109 17.1699 12.1152 17.1699Z" fill="#BAA989" stroke="#BAA989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>

                            }

                        </div>
                        <span
                            onClick={() => openModal('_modal_active')} 
                            className="_login_exist_form_forgot-link"
                        >
                            Forgot password
                        </span>
                        <button className="_button _is_primary _login_exist_form_button">
                            Login
                        </button>
                    </form>
                    {/* <span>

                    </span> */}
                </div>
                <BrandLogo csName="_login_logo" fill="#FFEECA" w={94.15} h={38}/>
            </div>
        </>
    );
}
 
export default LoginForm;