import { RETURN_POLICIES_DETAIL_FAILURE, RETURN_POLICIES_DETAIL_SUCCESS, RETURN_POLICIES_DETAIL_REQUEST } from "../actions/actionTypes";

const fetchReturnPolicies = (state = { data: null, loading: false, error: null }, action) => {
  switch (action.type) {
    case RETURN_POLICIES_DETAIL_REQUEST:
      return { ...state, loading: true };
    case RETURN_POLICIES_DETAIL_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case RETURN_POLICIES_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default fetchReturnPolicies;