import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

// Default theme
import '@splidejs/react-splide/css';

// Redux
import store from './redux/store';
import { Provider } from 'react-redux';
import routes from './auth/Routes';
import ScrollToTopOnRouteChange from './components/utilities/ScrollToTopOnRouteChange';
import Header from './components/Header';
import Footer from './components/Footer';
import UpwardArrow from './components/utilities/UpwardArrow';
import { ToastContainer } from 'react-toastify';
import Cart from './components/test/Cart';
import NotFound from './pages/NotFound';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Provider store={store}>
        <Header/>
        <Routes>
          <Route path="/test" element={<Cart/>}/>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element}>
              {route.children && route.children.map((child, index) => (
                <Route key={index} path={child.path} element={child.element} />
              ))}
            </Route>
          ))}
        <Route path='*' exact={true} element={<NotFound/>}/>
        </Routes>
        <UpwardArrow/>
        <Footer/>
        <ToastContainer />
      </Provider>
  </BrowserRouter>
);


reportWebVitals();
