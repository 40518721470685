import { useState } from 'react';
import baseAPI from '../../API/baseAPI';
import endPoints from '../../API/endPoints';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCartFailure, fetchCartRequest, fetchCartSuccess } from '../../redux/actions/cart/fetchCart';
import { toast } from 'react-toastify';

const useCart = () => {
  const [cart, setCart] = useState(null);

  const dispatch = useDispatch();

  const fetchCartAPI = async (cartId) => {
    dispatch(fetchCartRequest());
    try {
      const response = await baseAPI.get(`/${endPoints.CART.fetchCart}/${cartId}`);
      console.log(response.data)
      setCart(response.data);
      dispatch(fetchCartSuccess(response.data));
    } catch (error) {
      console.error("Error fetching cart:", error);
      dispatch(fetchCartFailure(error.message));
    }
  };

  const createCart = async (userId, status) => {
    try {
      const response = await baseAPI.post(`/${endPoints.CART.createCart}`, { userId, status });
      const newCartId = response.data.cartId;

        
      fetchCartAPI(newCartId);
    } catch (error) {
      console.error("Error creating cart:", error);
    }
  };

  
  
  const cartItems = useSelector(state => state?.cartDetails?.data);

  const addItemToCart = async (cartId, productCode, productName, quantity, price) => {
    const existingCartItem = cartItems.find(item => item.productCode === productCode);

    try {
      const existingCartItem = cartItems.find(item => item.productCode === productCode);

      if (existingCartItem) {
        const newQuantity = existingCartItem.quantity + quantity;
        let response = await baseAPI.put(`/cart/${cartId}/items/${existingCartItem.cartItemId}`, { quantity: newQuantity });

        if(response.status == 200) {
          fetchCartAPI(cartId);
          toast.success(`${newQuantity} Quantity updated in cart`);
        }

      } else {
        const response = await baseAPI.post(`/${endPoints.CART.addItemToCart}/${cartId}/items`, { productCode, productName, quantity, price });
        
        if(response.status == 201) {
          fetchCartAPI(cartId);
          console.log(response, "quantity")
          toast.success("Successfully added to cart")
        }
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  return { cart, fetchCartAPI, createCart, addItemToCart };
};

export default useCart;