import WhatWeDo from "./WhatWeDo";
import WhyChooseUs from "./WhyChooseUs";

const AboutUsSection = () => {
    return (
        <>
            <section className="_about-us_section">
                <div className="_about-us_section_wrapper _container">
                    <div className="_about-us_section_welcome">
                        <h2 className="_sub_h1 _about-us_section_welcome_title">
                            Welcome to SnoreQuit
                        </h2>
                        <p>
                            We are a team of passionate and innovative professionals who want to help people improve their sleep and health. We believe that snoring is not only a nuisance, but also a serious health risk that can lead to various complications such as sleep apnea, hypertension, heart disease and stroke. That’s is why we created SnoreQuit, a range of snoring stop devices that are designed to provide effective, comfortable. and affordable solutions for snorers and their partners
                        </p>
                    </div>
                    <WhatWeDo/>
                </div>
                <div className="_about-us_section_our-products_desc">
                    <div className="_container">
                        All our products are made of high-quality materials that are safe, durable, and easy to use. They are also backed by a 15-day money-back guarantee and a result oriented products
                    </div>
                </div>
            </section>
            <WhyChooseUs/>
        </>
    );
}
 
export default AboutUsSection;