const OrderHistoryCard = () => {
    return (
        <>
            <div className="_order_history_card">
                <div className="_order_history_card_product">
                    <figure>
                        <img src="./assets/imgs/utils/product-card_01.png"/>
                    </figure>
                    <div className="_order_history_card_product_info">
                        <h4 className="_order_history_card_product_info_title">
                            SnoreQuit Anti-Snoring Mouthpiece - 2 size pack $68.00
                            <span>$68.00</span>
                        </h4>
                        <p className="_order_history_card_product_info_order-id">Order ID: 0001111</p>
                        <div className="_order_history_card_product_info_quantity">
                            <span>Quantity</span>
                            <span>1</span>
                        </div>
                    </div>
                </div>
                <div className="_order_history_card_buttons">
                    <button className="_order_history_card_button _button _is_large _is_primary">Track order</button>
                    <button className="_button _is_outline _is_small _order_history_card_button">Product Info</button>
                    <button className="_button _is_outline _is_small _order_history_card_button">Invoice</button>
                    <button className="_order_history_card_button _button _is_large _is_disabled">Return</button>
                </div>
            </div>
        </>
    );
}
 
export default OrderHistoryCard;