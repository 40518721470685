import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import baseAPI from "../API/baseAPI";
import endPoints from "../API/endPoints";
import { tokenSuccess } from "../redux/actions/AuthToken";
import { useLocation, useNavigate } from "react-router-dom";

const PersonalInfoForm = () => {

    const user = useSelector(state => state.token?.data?.user);
    const userToken = useSelector(state => state.token?.data?.token);

    const [userDetails, setUserDetails] = useState({ name: '', number: '', email: '', city:'', zipCode:'', userId:'' });
    const [disabledFields, setDisabledFields] = useState({ name: true, number: true, email: true, city:true, zipCode:true });

    const toggleDisabled = (field) => {
        setDisabledFields((prevDisabledFields) => ({
            ...prevDisabledFields,
            [field]: !prevDisabledFields[field],
        }));
    };



    // ==============================================================================
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    
    const checkUserToken = async () => {
        try {
          const response = await baseAPI.get(`/${endPoints.AUTH.checkTokenExpiration}`, {
            headers: { Authorization: userToken }
          });
          if(response.status == 200) {
            dispatch(tokenSuccess(response.data));
          }
        } catch (error) {
       
        }
    }

    useEffect(() => {
        checkUserToken();
    }, []);

    // ==============================================================================

    const updateUserDetail = async (formData) => {
        try {
            
            let data = {
                number:formData.number,
                name:formData.name,
                email:formData.email,
                city:formData.city,
                zipCode:formData.zipCode,
                userId:formData.userId
            }
            
            let response = await baseAPI.put(`/${endPoints.USER.updateUserBasicDetails}`, data);
            
            if(response.status == 200) {
                checkUserToken();
                toast.success('Succesfuly Updated')
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(userDetails.userId.length == '8') {
            updateUserDetail(userDetails)
        }
        const form = e.currentTarget;
    };

    useEffect(() => {
        if(user != undefined) {
            const { name, number, email, city, zipCode, userId } = user;
            
            setUserDetails(prev => ({
                ...prev,
                name,
                number,
                userId,
                email: email !== undefined && email !== null ? email : "",
                city: city !== undefined && city !== null ? city : "",
                zipCode: zipCode !== undefined && zipCode !== null ? zipCode : ""
            }));
        }

    }, [user]);

    const handleInput = (e) => {
        const { name, value, checked } = e.target;
        let newValue = value;
        
        newValue = value.replace(/[^0-9]/g, '');
        
        
        setUserDetails(prev => ({ ...prev, [name]: newValue }));
    };

    return (
        <>
            <div className="_personal_info_wrapper">
                <h6 className="_personal_info_title _sub_h2">
                User profile
                </h6>
            </div>
            <form className="_personal_info_form_items" name="changePass" onSubmit={handleSubmit}>
                <div className="_input_group">
                    <input
                        placeholder="Full Name"
                        value={userDetails?.name ? userDetails.name : ''}
                        name="name"
                        disabled={disabledFields.name}
                        onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
                    />
                    {/* <svg 
                        className="feather feather-edit" 
                        fill="none" 
                        height={18} 
                        stroke="currentColor" 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        viewBox="0 0 24 24" 
                        width={24} xmlns="http://www.w3.org/2000/svg"
                        onClick={() => toggleDisabled('name')}
                    >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" stroke="#BAA989"/>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" stroke="#BAA989"/>
                    </svg> */}
                </div>
                <div className="_input_group_wrapper">
                    <div className="_input_group_wrapper_row">
                        <div className="_input_group">
                            <input
                                placeholder="Email"
                                value={userDetails?.email}
                                name="name"
                                disabled={disabledFields.email}
                                onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                            />
                            <svg 
                                className="feather feather-edit" 
                                fill="none" 
                                height={18} 
                                stroke="currentColor" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                viewBox="0 0 24 24" 
                                width={24} xmlns="http://www.w3.org/2000/svg"
                                onClick={() => toggleDisabled('email')}
                            >
                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" stroke="#BAA989"/>
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" stroke="#BAA989"/>
                            </svg>
                        </div>
                        <div className="_input_group">
                            <input
                                placeholder="Phone number"
                                value={userDetails.number}
                                name="number"
                                disabled={disabledFields.number}
                                onChange={(e) => {
                                    if (e.target.value.length <= 10) {
                                        // setUserDetails({ ...userDetails, number: e.target.value })
                                        handleInput(e)
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <svg 
                                className="feather feather-edit" 
                                fill="none" 
                                height={18} 
                                stroke="currentColor" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                viewBox="0 0 24 24" 
                                width={24} xmlns="http://www.w3.org/2000/svg"
                                onClick={() => toggleDisabled('number')}
                            >
                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" stroke="#BAA989"/>
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" stroke="#BAA989"/>
                            </svg>
                        </div>
                    </div>
                    <div className="_input_group_wrapper_row">
                        <div className="_input_group">
                            <input
                                placeholder="City"
                                value={userDetails.city}
                                name="city"
                                disabled={disabledFields.city}
                                onChange={(e) => setUserDetails({ ...userDetails, city: e.target.value })}
                            />
                            <svg 
                                className="feather feather-edit" 
                                fill="none" 
                                height={18} 
                                stroke="currentColor" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                viewBox="0 0 24 24" 
                                width={24} xmlns="http://www.w3.org/2000/svg"
                                onClick={() => toggleDisabled('city')}
                            >
                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" stroke="#BAA989"/>
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" stroke="#BAA989"/>
                            </svg>
                        </div>
                        <div className="_input_group">
                            <input
                                placeholder="ZIP code"
                                value={userDetails.zipCode}
                                name="zipCode"
                                disabled={disabledFields.zipCode}
                                onChange={(e) => {
                                    if (e.target.value.length <= 6) {
                                        // setUserDetails({ ...userDetails, number: e.target.value })
                                        handleInput(e)
                                    }
                                }}                            />
                            <svg 
                                className="feather feather-edit" 
                                fill="none" 
                                height={18} 
                                stroke="currentColor" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                viewBox="0 0 24 24" 
                                width={24} xmlns="http://www.w3.org/2000/svg"
                                onClick={() => toggleDisabled('zipCode')}
                            >
                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" stroke="#BAA989"/>
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" stroke="#BAA989"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <button className="_button _is_primary _personal_info_form_button">
                    Update Info
                </button>
            </form>
        </>
    );
}
 
export default PersonalInfoForm;