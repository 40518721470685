import { useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';
import baseAPI from "../API/baseAPI";
import endPoints from "../API/endPoints";


const validationRules = {
    name: {
        minLength: 4,
        maxLength: 10,
        regex: /^[a-zA-Z]+$/,
    },
    number: {
        minLength: 5,
        maxLength: 10,
        regex: /^[0-9]+$/,
    },
    // email: {
    //     minLength: 5,
    //     maxLength: 10,
    //     regex: /^(([^<>()\[\]\\.,;:#$%&*\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    // },
    password: {
        minLength: 8,
        maxLength: 15,
        regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+}{:;?.,]{8,}$/,
    },
    confirmPassword: {
        minLength: 8,
        maxLength: 15,
        regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+}{:;?.,]{8,}$/,
    }
};

const NewUserForm = () => {

    const [inputField, setInputField] = useState({
        phoneNumber:''
    })

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);



    

    // Validations =========================================
    const [inputs, setInputs] = useState({
        name: '',
        number: '',
        // email: '',
        password: '',
        confirmPassword:''
    });
    const [errors, setErrors] = useState({});
    const inputRefs = useRef({});

    useEffect(() => {
        Object.keys(inputRefs.current).forEach(name => {
            inputRefs?.current[name]?.addEventListener('input', handleInput);
        });
        return () => {
            Object.keys(inputRefs.current).forEach(name => {
                inputRefs?.current[name]?.removeEventListener('input', handleInput);
            });
        };
    }, []);

    const handleInput = (e) => {
        const { name, value } = e.target;
        
        // For the phone number field, remove non-numeric characters
        let newValue = value;
        if (name === 'number') {
            newValue = value.replace(/[^0-9]/g, '');
        }
        
        setInputs(prev => ({ ...prev, [name]: newValue }));
        validateField(name, newValue);
    };

    const validateField = (name, value) => {
        const rules = validationRules[name];
        if (!rules) return;

        const { minLength, maxLength, regex } = rules;
        let isValid = regex.test(value);

        if (name === 'confirmPassword') {
            if (value !== inputs.password) {
                setErrors(prev => ({ ...prev, [name]: 'Passwords do not match' }));
            } else {
                setErrors(prev => ({ ...prev, [name]: false }));
            }
            return;
        }

        if (isValid && value.length <= maxLength) {
            setErrors(prev => ({ ...prev, [name]: false }));
        } else {
            setErrors(prev => ({ ...prev, [name]: true }));
        }
    };
    
    const renderError = (name) => {
        if (errors[name]) {
            if (name === 'confirmPassword' && errors[name] === 'Passwords do not match') {
                return <div className="_input_error">Passwords do not match</div>;
            }

            switch (name) {
                case 'number':
                    return <div className="_input_error">Invalid phone number</div>;
                // case 'email':
                //     return <div className="_input_error">Invalid email address</div>;
                case 'password':
                    return <div className="_input_error">Invalid password</div>;
                case 'confirmPassword':
                    return <div className="_input_error">Invalid password</div>;
                case 'name':
                    return <div className="_input_error">Invalid name</div>;
                default:
                    return <div className="_input_error">Invalid input</div>;
            }
        }
        return null;
    };
    
    // =====================================================
    

        
    // Handle form submission ==============================
    const areAllErrorsFalse = () => {
        return Object.values(errors).every(error => error === false);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        validateAllFields();

        if (areAllErrorsFalse()) {
            // Form is valid, proceed with submission
            const formInputs = new FormData();

            formInputs.append('name', inputs.name);
            formInputs.append('number', inputs.number);
            formInputs.append('password', inputs.password);
            formInputs.append('confirmPassword', inputs.confirmPassword);

            const config = {
                headers: {
                  'Content-Type': 'application/json'
                }
            };

            try {
                let response = await baseAPI.post(`/${endPoints.AUTH.signup}`,formInputs, config)
                if (response.status === 200) {
                    toast.success("Thanks! We are glad that you joined us..")
                    setInputs({
                        name: '',
                        number: '',
                        // email: '',
                        password: '',
                        confirmPassword:''
                    })
                }
            }
            catch (error) {
                toast.error(error.response.data)
            }
            
        } else {
            // Form is not valid, show errors
            toast.error("Form has errors");
        }
    };

    const validateAllFields = () => {
        Object.keys(inputs).forEach(name => validateField(name, inputs[name]));
    };

    // =====================================================

    return (
        <>
            <div className="_login_new">
                <h4 className="_login_new_title">
                    Create your account
                </h4>
                <form className="_login_new_form_items" onSubmit={handleSubmit}>
                    <div>
                        <input
                            placeholder="Full Name"
                            value={inputs.name}
                            name="name"
                            ref={el => inputRefs.current['name'] = el}
                            className={errors.name ? '_has_error' : ''}
                            required
                            onChange={handleInput}
                        />
                        {renderError('name')}
                    </div>
                    <div>
                        <input
                            placeholder="Phone Number"
                            value={inputs.number}
                            name="number"
                            type="text"
                            onChange={handleInput}
                            ref={el => inputRefs.current['number'] = el}
                            className={errors.number ? '_has_error' : ''}
                            required
                        />
                        {renderError('number')}
                    </div>
                    <div>
                        <input
                            type="password"
                            placeholder="Password"
                            value={inputs.password}
                            name="password"
                            onChange={handleInput}
                            ref={el => inputRefs.current['password'] = el}
                            className={errors.password ? '_has_error' : ''}
                            required
                        />
                        {renderError('password')}
                    </div>
                    <div>
                        <input
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={inputs.confirmPassword}
                            onChange={handleInput}
                            ref={el => inputRefs.current['confirmPassword'] = el}
                            className={errors.confirmPassword || inputs.confirmPassword !== inputs.password ? '_has_error' : ''}
                        />
                        {renderError('confirmPassword')}
                    </div>
                    <button className="_button _is_primary _contact_us_form_button">
                     {windowWidth > 1024 ? 'Create account' : 'Signup'}
                    </button>
                </form>
            </div>
        </>
    );
}
 
export default NewUserForm;