import OrderHistoryCard from "./OrderHistoryCard";

const OrderHistory = () => {
    return (
        <>
            <div className="_order_history_wrapper">
                <h6 className="_order_history_title _sub_h2">
                    Order history
                </h6>
                <div className="_order_history_info">
                    <div className="_order_history_stage">
                        <h6 className="_order_history_stage_title">Order status: In-transit</h6>
                        <OrderHistoryCard/>
                    </div>
                    <div className="_order_history_stage">
                        <h6 className="_order_history_stage_title">Order status: Delivered</h6>
                        
                        <div className="_order_history_stage_responsive">
                            <OrderHistoryCard/>
                            <OrderHistoryCard/>
                        </div>

                    </div>
                    <div className="_order_history_stage">
                        <h6 className="_order_history_stage_title">Order status: Return placed</h6>
                        <div>
                        <OrderHistoryCard/>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
 
export default OrderHistory;