import axios from "axios";
const isProd = process.env.NODE_ENV === "production";
const baseURL = isProd ? '/api' : 'http://127.0.0.1:8081';
// const baseURL = window.location.protocol === 'https:' 
//   ? 'http://52.66.197.118:8081' 
//   : 'http://52.66.197.118:8081';

// const baseURL = window.location.protocol === 'https:' 
//   ? 'https://snorequit.in' 
//   : 'https://snorequit.in';

export default axios.create({
  baseURL: baseURL,
});
