import { useEffect } from "react";
import { fetchFAQentries } from "../../redux/actions/fetchFAQentries";
import { fetchProductContent } from "../../redux/actions/fetchProductContent";
import { fetchTabDetails } from "../../redux/actions/fetchTabDetails";
import { fetchPrivacyPolicies } from "../../redux/actions/fetchPrivacyPolicies";
import { fetchReturnPolicies } from "../../redux/actions/fetchReturnPolicies";
import { fetchReviews } from "../../redux/actions/fetchReviews";
import { fetchProductDetail } from "../../redux/actions/fetchProductDetail";
import { fetchAddress } from "../../redux/actions/fetchAddress";
import { fetchAndCombineProductsData } from "../../redux/actions/fetchAndCombineProductsData";
// import { fetchCart } from "../../redux/actions/cart/fetchCart";

const useApiCalls = (dispatch, userToken, userId) => {
  useEffect(() => {
    dispatch(fetchFAQentries());
    dispatch(fetchAndCombineProductsData());
    // dispatch(fetchCart());
    dispatch(fetchProductContent());
    dispatch(fetchTabDetails());
    dispatch(fetchPrivacyPolicies());
    dispatch(fetchReturnPolicies());
    dispatch(fetchReviews());
    dispatch(fetchProductDetail());
  }, [dispatch]);

  useEffect(() => {
    if (userToken) {
      dispatch(fetchAddress(userId));
    }
  }, [dispatch, userToken, userId]);
};

export default useApiCalls;