import YouTubeVideo from "../utilities/YouTubeVideo";

const ReviewVideo = () => {
    return (
        <>
            <section className="_review_video">
                <div className="_container _review_video_wrapper">
                    <div className="_review_video_info">
                        <h3 className="_sub_h1 _review_video_title">Testimonials</h3>
                    </div>
                    <div className="_review_video_media">
                        <YouTubeVideo
                            videoId="OJ3ErrDqveU?si=9IT-931jZvHgrmg8"
                            title="Snoring Solution"
                            thumbnail={"/assets/imgs/index/review_video.png"}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default ReviewVideo;