import { RETURN_POLICIES_DETAIL_FAILURE, RETURN_POLICIES_DETAIL_REQUEST, RETURN_POLICIES_DETAIL_SUCCESS } from "./actionTypes";
import endPoints from "../../API/endPoints"
import baseAPI from "../../API/baseAPI";

export const fetchReturnPoliciesRequest = () => ({
    type: RETURN_POLICIES_DETAIL_REQUEST,
});
  
export const fetchReturnPoliciesSuccess = (data) => ({
    type: RETURN_POLICIES_DETAIL_SUCCESS,
    payload: data,
});
  
export const fetchReturnPoliciesFailure = (error) => ({
    type: RETURN_POLICIES_DETAIL_FAILURE,
    payload: error,
});

export const fetchReturnPolicies = () => {
    return async (dispatch) => {
        dispatch(fetchReturnPoliciesRequest());
        try {
            const response = await baseAPI.get(`/${endPoints.RETURN_POLICIES.fetchReturnPolicies}`);
            
            dispatch(fetchReturnPoliciesSuccess(response.data));
        } catch (error) {
            dispatch(fetchReturnPoliciesFailure(error.message));
        }
    };
};

