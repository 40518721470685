import { useEffect, useState } from "react";
import CreditCardForm from "./utilities/tabs/forms/CreditCardForm";
import DeditCardForm from "./utilities/tabs/forms/DeditCardForm";
import OtherPaymentForm from "./utilities/tabs/forms/OtherPaymentForm";
import { Tab, TabContent } from "./utilities/tabs/Tab";

const CompletePayment = () => {
    const [visibleTab, setVisibleTab] = useState();

    let tabsData = [
        {
            "_id":1,
            "el": <CreditCardForm/>,
            "csName":"_credit_card_form",
            "associatedTabs":[1]
        },
        {
            "_id":2,
            "el": <DeditCardForm/>,
            "csName":"_debit_card_form",
            "associatedTabs":[2]
        },
        {
            "_id":2,
            "el": <OtherPaymentForm/>,
            "csName":"_other_payment_form",
            "associatedTabs":[3]
        }
    ]
    
    let tabs = [
        {
            "_tabId":1,
            "tabName":"Credit card",
        },
        {
            "_tabId":2,
            "tabName":"Debit card",
        },
        {
            "_tabId":3,
            "tabName":"Other",
        }
    ]

    useEffect(() => {
        if (tabs && tabs.length > 0) {
            setVisibleTab(tabs[0]._tabId);
        }
    }, []);

    return (
        <>
            <div className="_payment">
                <div className="_payment_tab_wrapper">
                    <div className="_payment_head">
                        <ul className="_tab_head">
                            {tabs && tabs.map((tab) => (
                                <Tab
                                    key={tab._tabId}
                                    tab={tab}
                                    visibleTab={visibleTab}
                                    setVisibleTab={setVisibleTab}
                                />
                            ))}
                        </ul>
                    </div>
                    <div className="_payment_body">
                        <div className="_tab_body">
                            {tabs && tabs.map((tab) => (
                                <TabContent
                                    key={tab._tabId}
                                    tab={tab}
                                    visibleTab={visibleTab}
                                    el={tabsData}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
        </>
    );
}
 
export default CompletePayment;