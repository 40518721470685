import baseAPI from '../../API/baseAPI';
import endPoints from '../../API/endPoints';

export const fetchAndCombineProductsData = () => async (dispatch) => {
  try {
    const [basicDetailResponse, productContentResponse] = await Promise.all([
        baseAPI.get(`/${endPoints.PRODUCT_BASIC_DETAILS.fetchProductDetails}`),
        baseAPI.get(`/${endPoints.PRODUCT_BASIC_DETAILS.fetchProductContent}`)
    ]);


    const basicDetails = basicDetailResponse?.data;
    const productContents = productContentResponse?.data?.productsContent;
    
    // Combine the data
    const combinedData = basicDetails.map(basicDetail => {
      const productContent = productContents.find(
          content => content.productCode === basicDetail.relatedProductCode
      );

      return {
        ...basicDetail,
        ...productContent
      };
    });
    

    dispatch({
      type: 'SET_COMBINED_DATA',
      payload: combinedData
    });
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};