import baseAPI from "../API/baseAPI";
import useCart from './utilities/useCart';


const CartItem = ({tab, item, qtyBadge}) => {
    const { fetchCartAPI } = useCart();

    const updateQuantity = async (cartId, cartItemId, quantity) => {
        if (quantity < 1) {
            await deleteItem(cartId, cartItemId);
        } else {
            await baseAPI.put(`/cart/${cartId}/items/${cartItemId}`, { quantity });
        }
        fetchCartAPI(cartId);
    };
    
    const deleteItem = async (cartId, cartItemId) => {
        await baseAPI.delete(`/cart/${cartId}/items/${cartItemId}`);
        fetchCartAPI(cartId);
    };

    // code save for future use to another website
    // const handleUpdateCartQuantity = (productId, quantity) => {
    //     dispatch(updateCartQuantity(productId, quantity));
    // };
    
    return (
        <>
            <div className="_cart_item_card">
                <div className="_cart_item_card_product">
                    <figure>
                        {qtyBadge && (
                            <span className="_cart_item_card_qty">{item.quantity}</span>
                        )}
                        <img src="./assets/imgs/utils/product-card_01.png"/>
                    </figure>
                    <div className="_cart_item_card_product_info">
                        <h4 className="_cart_item_card_product_info_title">
                            {item.productName}
                            <span>₹{item.price}</span>
                        </h4>
                        <div className={`_cart_item_card_product_info_quantity`} style={{display:`${tab == 'shipping' ? 'none' : 'flex'}`}}>
                            <span>Quantity</span>
                            <div className="_cart_item_card_product_info_quantity_wrapper">
                                <button onClick={() => updateQuantity(item.cartId, item.cartItemId, item.quantity - 1)}>-</button>
                                <button>{item?.quantity}</button>
                                <button onClick={() => updateQuantity(item.cartId, item.cartItemId, item.quantity + 1)}>+</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default CartItem;