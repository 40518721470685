import ShippingAddForm from "../components/ShippingAddForm";
import CartSummary from "../components/sections/CartSummary";
import ProductCarousel from "../components/sections/ProductCarousel";

const Payment = () => {
    return (
        <>
            <section className="_container _payment_section">
                <ShippingAddForm/>
                <CartSummary title="Complete purchase" page="payment"/>
            </section>
            <ProductCarousel title="Popular Purchases" page="cart"/>
        </>
    );
}
 
export default Payment;