import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Hero = ({page, title, btn, desc}) => {
        // Width Body Calculate
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            // Function to update window width
            const handleResize = () => {
              setWindowWidth(window.innerWidth);
            };
        
            // Add event listener
            window.addEventListener('resize', handleResize);
        
            // Clean up event listener on component unmount
            return () => {
              window.removeEventListener('resize', handleResize);
            };
        }, []);

    return (
        <>
            <section className={`_hero _${page}`}>
                {
                    windowWidth > 1025 ?
                        <img className="_hero_banner" src={`https://sqspapps3.s3.ap-south-1.amazonaws.com/assets/media/images/pages/${page}/hero/banner.png`}/>
                        :
                        <img className="_hero_banner" src={`https://sqspapps3.s3.ap-south-1.amazonaws.com/assets/media/images/pages/${page}/hero/banner-responsive.png`}/>
                }
                <div className="_hero_overlay"></div>
                <div className="_hero_wrapper _container">
                    <div className="_hero_info">
                        {
                            title ? 
                            <>
                                <h1 className="_h1 _hero_title">
                                    {title}
                                </h1>
                            </>
                            :
                            null
                        }
                        {
                            desc ? 
                            <>
                                <p>
                                    {desc}
                                </p>
                            </>
                            :
                            null
                        }
                        {
                            btn ? 
                            <>
                                <Link to="/shop" className="_button _is_primary _is_medium">
                                    {btn}
                                </Link>
                            </>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="_hero_strap">
                    <div className="_hero_strap_wrapper">
                        <div>Silent Nights</div>
                        <div>Sleep Better</div>
                        <div>Rest Easy</div>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default Hero;