import { useSelector } from "react-redux";
import FlipkartBuyButton from "./utilities/FlipkartBuyButton";
import AmazonBuyButton from "./utilities/AmazonBuyButton";
import { useEffect, useState } from "react";
import useCart from "./utilities/useCart";

const DetailedProductCard = () => {
    const [ productContent, setProductContent ] = useState([]);

    const { addItemToCart } = useCart();

    let productsContent = useSelector(state => state?.productsContent?.data?.productsContent)
    // let productsDetail = useSelector(state => state?.productsDetail?.data?.productsList[0])
    let productsDetail = useSelector(state => state?.productsDetail?.data[0])

    const { data, loading, error } = useSelector((state) => state.productsDetail);

    useEffect(() => {
        if(productsContent && productsDetail != undefined) {
            const product = productsContent.find(product => product.relatedProductCode === productsDetail.productCode);
            setProductContent(product);
        }
    }, [productsContent])
    const cartItems = useSelector(state => state?.cartDetails?.data);
    

    if (loading) return (
        <div className="smart-glass">
          <div class="logo">
            <div class="circle">
              <div class="circle">
                <div class="circle">
                </div>
              </div>
            </div>
            <div class="loading-text">
              Loading...
            </div>
          </div>
        </div>
        );
    if (error) return <div>Error: {error}</div>;
    if (!data) return null;



    const handleAddToCart = (cartId, productCode, productName, quantity, price) => {
        if(cartItems[0]?.cartId) {
            let cartId = cartItems[0]?.cartId;
            addItemToCart(cartId, productsDetail.productCode, productsDetail.productName, 1, productsDetail.currentPrice);
        } else {
            console.log("cart id not created")
        }
    };
    
    return (
        <>
            <div className="_detailed_productCard">
                <div className="_container _detailed_productCard_wrapper">
                    <div className="_detailed_productCard_media">
                        <img src={`${productContent.img}`}/>
                    </div>
                    <div className="_detailed_productCard_body">
                        <div className="_detailed_productCard_title_wrapper">
                            {
                                productsDetail != undefined ? 
                                <h2 className="_detailed_productCard_title _sub_h2">
                                    {productsDetail && productsDetail?.productName}
                                </h2>
                                :
                                null
                            }
                            <span className="_detailed_productCard_tagline">
                            Your Snoring Solution for Restful Nights.
                            </span>
                        </div>
                        <div className="_detailed_productCard_desc_wrapper">
                            <p className="_detailed_productCard_desc_typo">Description</p>
                            <div className="_detailed_productCard_desc_text">
                                {productContent?.desc?.desc ? <p>{productContent?.desc?.desc}</p> : null}
                                {productContent?.desc?.key_points ? 
                                    <ul className="_detailed_productCard_desc_text_keypoints">
                                        {
                                            productContent?.desc?.key_points.map((keyPoint, index) => (
                                                <li className="_detailed_productCard_desc_text_keypoint" key={index}>
                                                    <span>{keyPoint?.title}:</span>
                                                    <span>{keyPoint?.desc}</span>
                                                </li>       
                                            ))
                                        } 

                                    </ul>
                                    : 
                                    null
                                }
                                {productContent?.desc?.desc ? productContent?.desc?.desc : null}
                            </div>
                        </div>
                        <div className="_detailed_productCard_price_wrapper">
                        {
                            productsDetail != undefined ? <>
                            <span className="_product_card_info_price_mrp">{productsDetail.defaultPrice}</span>
                            <span className="_product_card_info_price_offer">₹<span>{productsDetail.currentPrice}</span></span>
                            </>
                            :
                            null
                        }
                        </div>
                        <div className="_product_card_buttons">
                            <FlipkartBuyButton size="_is_large" handleAddToCart={handleAddToCart}/>
                            <AmazonBuyButton size="_is_large"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default DetailedProductCard;