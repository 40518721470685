import { Link } from "react-router-dom";
import AmazonBuyButton from "./utilities/AmazonBuyButton";
import FlipkartBuyButton from "./utilities/FlipkartBuyButton";
import { useEffect } from "react";
import useCart from "./utilities/useCart";
import { useSelector } from "react-redux";


const ProductCard = ({product}) => {
    const { addItemToCart } = useCart();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                const cardTitles = document.querySelectorAll('._product_card_info');
                let maxHeight = 0;

                // Calculate the maximum height
                cardTitles.forEach(card => {

                    maxHeight = Math.max(maxHeight, card.scrollHeight);
                });

                // Set all cards to the maximum height
                cardTitles.forEach(card => {
                    card.style.height = `${maxHeight + 2}px`;
                });
            } else {
                // Reset height if width is greater than 768px
                const cardTitles = document.querySelectorAll('._product_card_info');
                cardTitles.forEach(card => {
                    card.style.height = 'auto';
                });
            }
        };

        // Initial check
        handleResize();

        // Add resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [product]);


    const cartItems = useSelector(state => state?.cartDetails?.data);

    const handleAddToCart = (cartId, productCode, productName, quantity, price) => {
        if(cartItems[0]?.cartId) {
            let cartId = cartItems[0]?.cartId;
            addItemToCart(cartId, product.productCode, product.productName, 1, product.currentPrice);
        } else {
            console.log("cart id not created")
        }
    };

    
    return (
        <>
            <div className="_product_card">
                <div className="_product_card_media">
                    <img src={`https://sqspapps3.s3.ap-south-1.amazonaws.com/assets/media/images/products/${product.productCode}/thumbnail/${product.productCode}-T1.png`}/>
                </div>
                <div className="_product_card_body">
                    <div className="_product_card_info">
                        <h6>
                            <Link className="_product_card_link" to={`/product-detail/${product.productId}`}>
                            </Link>
                            {product.productName}
                        </h6>
                        <div className="_product_card_info_price">
                            <span className="_product_card_info_price_mrp">{product.defaultPrice}</span>
                            <span className="_product_card_info_price_offer">₹<span>{product.currentPrice}</span></span>
                        </div>
                    </div>
                    <p className="_product_card_info_text">
                        Your Snoring Solution for Restful Nights.
                    </p>
                    <div className="_product_card_buttons">
                        <FlipkartBuyButton handleAddToCart={handleAddToCart}/>
                        <AmazonBuyButton/>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default ProductCard;