import CartSummary from "../components/sections/CartSummary";
import ProductCarousel from "../components/sections/ProductCarousel";

const Cart = () => {
    return (
        <>
            <CartSummary page="cart"/>
            <ProductCarousel title="Popular Purchases" page="cart"/>
        </>
    );
}
 
export default Cart;