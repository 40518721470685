const NotFound = () => {
    return (
        <>
            <div className="_not_found">
                <h1 className="_not_found_title">
                    404
                </h1>
                <p>Sorry, we couldn’t find this page</p>
            </div>
        </>
    );
}
 
export default NotFound;