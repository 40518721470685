const WhyChooseUs = () => {
    return (
        <>
            <section className="_why-choose-us_section">
                <div className="_why-choose-us_section_wrapper _container">
                    <div>
                        <div className="_why-choose-us_section_info">
                            <h4 className="_sub_h2 _why-choose-us_section_title">Why choose us?</h4>
                            <p className="_why-choose-us_section_text">
                                We are more than just a seller of snoring stop devices. We are a trusted partner who cares about your well-being and satisfaction. When you choose us, you can expect
                            </p>
                        </div>
                        <ul className="_why-choose-us_section_desc_list">
                            <li className="_why-choose-us_section_desc_list_item">
                                <span className="_why-choose-us_section_desc_list_item_highlight">Fast and free shipping:</span>
                                We Ship our products within 24 hours of receiving your order and deliver them to your doorstep within 3-5 business days
                            </li>
                            <li className="_why-choose-us_section_desc_list_item">
                                <span className="_why-choose-us_section_desc_list_item_highlight">Secure and convenient payment:</span>
                                 We accept all major credit cards, UPI Payment methods and other online payment methods. We also use SSL encryption to protect your personal and financial information.
                            </li>
                            <li className="_why-choose-us_section_desc_list_item">
                                <span className="_why-choose-us_section_desc_list_item_highlight">Secure and convenient payment:</span>
                                 We accept all major credit cards, UPI Payment methods and other online payment methods. We also use SSL encryption to protect your personal and financial information.
                            </li>
                            <li className="_why-choose-us_section_desc_list_item">
                                <span className="_why-choose-us_section_desc_list_item_highlight">Friendly and professional customer service:</span>
                                 We are always ready to answer your questions, address your concerns, and provide you with helpful tips and advice. You can reach us by phone, email, or live chat.
                            </li>
                            <li className="_why-choose-us_section_desc_list_item">
                                <span className="_why-choose-us_section_desc_list_item_highlight">Expert and honest guidance: </span>
                                We are not here to sell you anything you don’t need. We are here to help you find the best solution for your snoring problem. We will assess your situation, recommend the most suitable product, and guide you through the process of using it. Your satisfaction is our top priority. Our dedicated customer service team is always ready to assist you with any queries or concerns.
                            </li>
                            <li className="_why-choose-us_section_desc_list_item">
                                <span className="_why-choose-us_section_desc_list_item_highlight">Affordable prices: </span>
                                 We believe that effective snoring solutions should be accessible to everyone. Our competitive pricing ensures that you get the best value for your money.
                            </li>
                            <li className="_why-choose-us_section_desc_list_item">
                                <span className="_why-choose-us_section_desc_list_item_highlight">Innovative solutions: </span>
                                We continually invest in research and development to bring you the latest advancements in snoring cessation technology and products.
                            </li>
                            <li className="_why-choose-us_section_desc_list_item">
                            We are confident that our snoring stop devices will make a positive difference in your life.But don’t just take our word for it, read the testimonials of our happy customers and see for yourself how SnoreQuit has helped them sleep better, breathe easier and live healther.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="_why-choose-us_section_info">
                            <h4 className="_sub_h2 _why-choose-us_section_title">SnoreQuit Offers Effective Solutions for Snoring!</h4>
                        </div>
                        <ul className="_why-choose-us_section_desc_list _why-choose-us_section_desc_list_dot">
                            <li className="_why-choose-us_section_desc_list_item">
                                Do you want to stop snoring and improve your quality of sleep? SnoreQuit can help you with its range of products that target the root cause of snoring. Whether you need a SnoreQuit mouthpiece that adjusts your jaw position, earplugs for sleeping, nasal strips, or other snoring aids, we have what you need.
                            </li>
                            <li className="_why-choose-us_section_desc_list_item">
                            SnoreQuit was founded in 2023 with the launch of its first product: the SnoreQuit mouthpiece, developed with the expertise of dental and medical professional. Our SnoreQuit devices have helped millions of people stop snoring.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default WhyChooseUs;