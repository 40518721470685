import { useEffect, useState } from "react";
import Accordion from "../utilities/Accordion";
import { useSelector } from "react-redux";


const FAQSection = ({product}) => {
    const [active, setActive] = useState(null);
    const [faqs, setFAQs] = useState([]);
    let faqEntries = useSelector(state => state?.FAQentries?.data?.faqEntries);
    const { data, loading, error } = useSelector((state) => state?.FAQentries);

    const handleToggle = (i) => {
        if (active === i) {
            setActive(null);
        } else {
            setActive(i);
        }
    }

    useEffect(() => {
        if (faqEntries) {
            let arr = [];
            const filteredFaqs = faqEntries.filter(faq => {
               return faq.relatedProducts.includes(product.productCode)
            });
            setFAQs(filteredFaqs)
        }
        
    }, [product]);


    if (loading) return (
        <div className="smart-glass">
          <div class="logo">
            <div class="circle">
              <div class="circle">
                <div class="circle">
                </div>
              </div>
            </div>
            <div class="loading-text">
              Loading...
            </div>
          </div>
        </div>
        );
    if (error) return <div>Error: {error}</div>;
    if (!data) return null;

    return (
        <> 
            {
                faqs.length > 0 ?
                    <section className="_faq-section">
                        <div className="_faq-section_wrapper _container">
                            <div className="_faq-section_info">
                                <h4 className="_faq-section_title _sub_h2">
                                Frequently asked questions:
                                </h4>
                            </div>
                            <div className="_faq-section_body _product-detail">
                                {
                                    faqs.map((faq, index) => (
                                        <Accordion active={active} handleToggle={handleToggle} faq={faq}/>
                                    ))
                                }
                            </div>
                        </div>
                    </section>
                :
                null
            }
        </>
    );
}
 
export default FAQSection;