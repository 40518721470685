import BrandLogo from "../utilities/BrandLogo";

const AboutSnoreQuit = () => {
    return (
        <>
            <section className="_about_snoreQuit">
                <div className="_container _about_snoreQuit_wrapper">
                    <h3 className="_sub_h1 _about_snoreQuit_title">
                        We Help You End Snoring and Improve Sleep
                    </h3>
                    <div className="_about_snoreQuit_body">
                        <div className="_about_snoreQuit_info">
                            <span>
                                If you are looking for ways to deal with how to stop snoring, SnoreQuit provides a variety of options to help you stop snoring. Our SnoreQuit mouthpiece prevents what causes snoring at its root. We also have other snoring remedies, such as earplugs for sleeping, snoring aids like our nasal breathing aid, and more.
                            </span>
                            <span>
                                SnoreQuit was established in 2023 when it launched the first in its series of snoring solutions: the SnoreQuit mouthpiece, developed in partnership with dental and medical experts. This Made-In-India device has helped millions of people stop snoring.
                            </span>
                            <span>
                                SnoreQuit now offers a variety of products to help snorers and bed partners of loud snoring, get quiet, restful, snore-free nights of sleep.
                            </span>
                        </div>
                        <div className="_about_snoreQuit_media">
                            <img src="./assets/imgs/index/about_snoreQuit_01.png"/>
                            {/* <img src="./assets/imgs/index/about_snoreQuit_02.png"/> */}
                            <BrandLogo csName="_about_snoreQuit_media_logo" fill="#005B4C" h={118}/>
                            <BrandLogo csName="_about_snoreQuit_media_logo_for_responsive" fill="#005B4C" h={60}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default AboutSnoreQuit;