import { FETCH_ADDRESS_FAILURE, FETCH_ADDRESS_REQUEST, FETCH_ADDRESS_SUCCESS } from "./actionTypes";
import endPoints from "../../API/endPoints"
import baseAPI from "../../API/baseAPI";

export const fetchAddressRequest = () => ({
    type: FETCH_ADDRESS_REQUEST,
});
  
export const fetchAddressSuccess = (data) => ({
    type: FETCH_ADDRESS_SUCCESS,
    payload: data,
});
  
export const fetchAddressFailure = (error) => ({
    type: FETCH_ADDRESS_FAILURE,
    payload: error,
});

export const fetchAddress = (userId) => {
    return async (dispatch) => {
        dispatch(fetchAddressRequest());

        const formInputs = new FormData();
    
        formInputs.append('userId', userId);
    
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        try {
            const response = await baseAPI.post(`/${endPoints.USER.address}`, formInputs, config);
            dispatch(fetchAddressSuccess(response.data));
        } catch (error) {
            dispatch(fetchAddressFailure(error.message));
        }
    };
};

