const WhatWeDo = () => {
    return (
        <>
            <div className="_about-us_section_what-we-do">
                <h6 className="_sub_h2 _about-us_section_info_title">We Help You End Snoring and Improve Sleep</h6>
                <div className="_about-us_section_info">
                    <div className="_about-us_section_info_media">
                        <img src="./assets/imgs/aboutus/author.png"/>
                        <span>Zack</span>
                    </div>
                    <div className="_about-us_section_info_typo">
                        <h5 className="_sub_h2 _about-us_section_info_subtitle">What we do</h5>
                        <p>
                            <span>
                            We offer a variety of snoring stop devices that suit different needs and preferences. At SnoreQuit, we offer a range of snoring stop devices designed to meet diverse needs and preferences. Our products are rigorously tested for effectiveness and comfort, ensuring that you get the best possible solution tailored to your specific requirements. From nasal strips to mouthpieces, each device is crafted with precision and care to deliver maximum results
                            </span>
                        </p>
                    </div>
                </div>
                <div className="_about-us_section_our-products">
                    <h5 className="_sub_h2 _about-us_section_our-products_title">Our products include:</h5>
                    <div className="_about-us_section_our-products_products">
                        <div className="_about-us_section_our-product">
                            <h6 className="_about-us_section_our-product_title">SnoreQuit Anti-Snoring Mouthpieces</h6>
                            <p className="_about-us_section_our-product_text">A custom-fit device that gently advances the lower jaw to open the airway and reduce snoring.</p>
                        </div>
                        <div className="_about-us_section_our-product">
                            <h6 className="_about-us_section_our-product_title">SnoreQuit Anti-Snoring Nose Vents</h6>
                            <p className="_about-us_section_our-product_text">A set of reusable silicone vents that insert into the nostrils and improve nasal breathing.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default WhatWeDo;