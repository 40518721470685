import PrivacyPolicies from "../components/sections/PrivacyPolicies";

const Policies = () => {
    return (
        <>
            <PrivacyPolicies/>
        </>
    );
}
 
export default Policies;