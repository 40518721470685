import CreditCardForm from "./CreditCardForm"

const DeditCardForm = () => {
    return (
        <>
            <CreditCardForm/>
        </>
    );
}
 
export default DeditCardForm;