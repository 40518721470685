import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { fetchFAQentries } from "../redux/actions/fetchFAQentries";
import { fetchProductContent } from "../redux/actions/fetchProductContent";
import { fetchTabDetails } from "../redux/actions/fetchTabDetails";
import { fetchPrivacyPolicies } from "../redux/actions/fetchPrivacyPolicies";
import { fetchReturnPolicies } from "../redux/actions/fetchReturnPolicies";
import { fetchReviews } from "../redux/actions/fetchReviews";
import { fetchProductDetail } from "../redux/actions/fetchProductDetail";
import useApiCalls from "../components/utilities/useApiCalls";
import ScrollToTopOnRouteChange from "../components/utilities/ScrollToTopOnRouteChange";

const Auth = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { userToken, userId } = useSelector(state => ({
        userToken: state.token?.data?.token,

        userId: state.token?.data?.user?.userId,
    }));
    
    const checkUserToken = () => {
        if (!userToken || userToken === 'undefined' || userToken == null) {
        
            setIsLoggedIn(true);
            return navigate('/auth/login');
        }
        else {
            setIsLoggedIn(false);
            return navigate('/');
        }
    }
    
    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

  
    const { data, loading, error } = useSelector((state) => state.productsDetail);

    useApiCalls(dispatch, userToken, userId);


    if (loading) return (
        <div className="smart-glass">
          <div class="logo">
            <div class="circle">
              <div class="circle">
                <div class="circle">
                </div>
              </div>
            </div>
            <div class="loading-text">
              Loading...
            </div>
          </div>
        </div>
        );
    if (error) return <div>Error: {error}</div>;
    if (!data) return null;

    return (
        <>
            <Outlet/>
        </>
    );
}
 
export default Auth;