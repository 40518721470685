import React, { Children, useState } from 'react';

const Modal = ({isActive, closeModal, modalClass, children}) => {

  return (
    <div className={isActive ? `${modalClass} _modal_container` : '_modal_container'} onClick={closeModal}>
      <div className="_modal_background">
        <div className="_modal" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
