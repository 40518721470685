import { useEffect, useLayoutEffect, useRef, useState } from "react";
import BrandLogo from "./utilities/BrandLogo";
import { Link, useLocation } from 'react-router-dom'
import Menu from "./burger-menu/Menu";
import Burger from "./burger-menu/Burger";
import useOnClickOutside from "./burger-menu/useOnClickOutside";
import { useSelector } from "react-redux";

const Header = () => {
    const { pathname } = useLocation();
    const [activePage, setActivePage] = useState();
  
    useLayoutEffect(() => {
        setActivePage(pathname)
    }, [pathname])

    // Humburger Menu
    const [open, setOpen] = useState(false);
    const node = useRef();
    useOnClickOutside(node, () => setOpen(false));

    // Width Body Calculate
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    const user = useSelector(state => state.token?.data?.user);
    const cartItems = useSelector(state => state?.cartDetails?.data);
    

    const totalQuantity = cartItems?.reduce((total, item) => total + item.quantity, 0) || 0;


    return (
        <>
            <header className="_navbar">
                <div className="_container_block _navbar_wrapper">
                    <div className="_navbar_brand">
                        <Link to="/">
                            <BrandLogo fill="#005B4C" w={148} h={60}/>
                        </Link>
                    </div>
                        {
                            windowWidth > 1024 ?
                                <div className="_navbar_links">
                                    <div className="_navbar_link">
                                        <Link to="/why-snore" style={{ fontWeight: activePage === '/why-snore' ? '700' : 'normal' }}>Why you snore?</Link>
                                    </div>
                                    <div className="_navbar_link">
                                        <Link to="/shop"  style={{ fontWeight: activePage === '/shop' ? '700' : 'normal' }}>Shop</Link>
                                    </div>
                                    <div className="_navbar_link">
                                        <Link to="/faq-s" style={{ fontWeight: activePage === '/faq-s' ? '700' : 'normal' }}>FAQs</Link>
                                    </div>
                                    {
                                        user && user?.name ?
                                            <div className="_navbar_link _navbar_link_account">
                                                <Link to="/account">{user?.name?.slice(0,1)}</Link>
                                            </div>
                                        :
                                        null
                                    }
                                    {/* {
                                        user && user?.name ? */}
                                            <div className="_navbar_link _navbar_link_cart">
                                                <Link to="/cart">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={31} height={30} viewBox="0 0 31 30" fill="none">
                                                        <path d="M1.25586 2.0293H3.27665C4.01994 2.0293 4.67037 2.529 4.86188 3.24719L5.42071 5.34277M8.91211 18.4355C6.49586 18.4355 4.53711 20.3943 4.53711 22.8105H27.5059M8.91211 18.4355H25.2721C26.907 15.0804 28.3338 11.6052 29.5355 8.0267C22.5913 6.25329 15.3149 5.31055 7.81836 5.31055C7.01658 5.31055 6.21732 5.32133 5.42071 5.34277M8.91211 18.4355L5.42071 5.34277M6.72461 27.1855C6.72461 27.7896 6.23492 28.2793 5.63086 28.2793C5.0268 28.2793 4.53711 27.7896 4.53711 27.1855C4.53711 26.5815 5.0268 26.0918 5.63086 26.0918C6.23492 26.0918 6.72461 26.5815 6.72461 27.1855ZM25.3184 27.1855C25.3184 27.7896 24.8287 28.2793 24.2246 28.2793C23.6205 28.2793 23.1309 27.7896 23.1309 27.1855C23.1309 26.5815 23.6205 26.0918 24.2246 26.0918C24.8287 26.0918 25.3184 26.5815 25.3184 27.1855Z" stroke="#FCFCFC" strokeWidth="2.1875" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </Link>
                                                    {totalQuantity > 0 && (
                                                        <span className="_navbar_link_cart_qty">{totalQuantity}</span>
                                                    )}
                                            </div>
                                        {/* :
                                        null
                                    } */}
                                </div>
                            :
                                <div ref={node} style={{position:'relative'}}>
                                    <Burger open={open} setOpen={setOpen} />
                                    <Menu open={open} setOpen={setOpen} user={user}/>
                                </div>
                        }
                    </div>
            </header>
        </>
    );
}
 
export default Header;