// For productsDetailFetch
export const FETCH_PRODUCT_DETAILS_REQUEST = 'FETCH_PRODUCT_DETAILS_REQUEST';
export const FETCH_PRODUCT_DETAILS_SUCCESS = 'FETCH_PRODUCT_DETAILS_SUCCESS';
export const FETCH_PRODUCT_DETAILS_FAILURE = 'FETCH_PRODUCT_DETAILS_FAILURE';

// For productsContentFetch
export const FETCH_PRODUCT_CONTENT_REQUEST = 'FETCH_PRODUCT_CONTENT_REQUEST';
export const FETCH_PRODUCT_CONTENT_SUCCESS = 'FETCH_PRODUCT_CONTENT_SUCCESS';
export const FETCH_PRODUCT_CONTENT_FAILURE = 'FETCH_PRODUCT_CONTENT_FAILURE';


// For tabsDetailFetch
export const FETCH_TAB_DETAILS_REQUEST = 'FETCH_TAB_DETAILS_REQUEST';
export const FETCH_TAB_DETAILS_SUCCESS = 'FETCH_TAB_DETAILS_SUCCESS';
export const FETCH_TAB_DETAILS_FAILURE = 'FETCH_TAB_DETAILS_FAILURE';

// For fetchFAQentries
export const FETCH_FAQ_ENTRIES_REQUEST = 'FETCH_FAQ_ENTRIES_REQUEST';
export const FETCH_FAQ_ENTRIES_SUCCESS = 'FETCH_FAQ_ENTRIES_SUCCESS';
export const FETCH_FAQ_ENTRIES_FAILURE = 'FETCH_FAQ_ENTRIES_FAILURE';

// For fetchFAQentries
export const FETCH_ADDRESS_REQUEST = 'FETCH_ADDRESS_REQUEST';
export const FETCH_ADDRESS_SUCCESS = 'FETCH_ADDRESS_SUCCESS';
export const FETCH_ADDRESS_FAILURE = 'FETCH_ADDRESS_FAILURE';

// For fetchFAQentries
export const FETCH_REVIEWS_REQUEST = 'FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAILURE = 'FETCH_REVIEWS_FAILURE';

// For productWithAllDetails
export const PRODUCT_FULL_DETAIL_REQUEST = 'PRODUCT_FULL_DETAIL_REQUEST';
export const PRODUCT_FULL_DETAIL_SUCCESS = 'PRODUCT_FULL_DETAIL_SUCCESS';
export const PRODUCT_FULL_DETAIL_FAILURE = 'PRODUCT_FULL_DETAIL_FAILURE';

// For productWithAllDetails
export const PRIVACY_POLICIES_DETAIL_REQUEST = 'PRIVACY_POLICIES_DETAIL_REQUEST';
export const PRIVACY_POLICIES_DETAIL_SUCCESS = 'PRIVACY_POLICIES_DETAIL_SUCCESS';
export const PRIVACY_POLICIES_DETAIL_FAILURE = 'PRIVACY_POLICIES_DETAIL_FAILURE';

// For productWithAllDetails
export const RETURN_POLICIES_DETAIL_REQUEST = 'RETURN_POLICIES_DETAIL_REQUEST';
export const RETURN_POLICIES_DETAIL_SUCCESS = 'RETURN_POLICIES_DETAIL_SUCCESS';
export const RETURN_POLICIES_DETAIL_FAILURE = 'RETURN_POLICIES_DETAIL_FAILURE';

// For User Auth Token
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';

// For add to cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY';
export const CLEAR_CART = 'CLEAR_CART';

// For fetch
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';
export const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';