import { Step, StepLabel, Stepper } from "@mui/material";
import CartItem from "../CartItem";
import React from "react";
import ShippingAddForm from "../ShippingAddForm";
import { useSelector } from "react-redux";


import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Link } from "react-router-dom";
import CompletePayment from "../CompletePayment";

const CartSummary = ({page}) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const cartItems = useSelector(state => state?.cartDetails?.data);
    const products = useSelector(state => state?.products.combinedData);

    const getProductDetails = (productCode) => {
        return products.find(product => product.productCode === productCode);
    };
    

    const totalQuantity = cartItems?.reduce((total, item) => total + item.quantity, 0) || 0;
    const totalCost = cartItems?.reduce((total, item) => total + (item.quantity * item.price), 0) || 0;
  

    const getTitle = () => {
        switch (activeStep) {
            case 0:
                return "Your Cart";
            case 1:
                return "Complete Purchase";
            case 2:
                return "Complete purchase";
            case 2:
                return "Payment Successful";
            default:
                return "Unknown Step";
        }
    };
  
    const steps = [
        'Cart',
        'Shipping',
        'Payment',
        "Confirmation"
    ];

    const handleNext = (e) => {    
        e.preventDefault();
        
        setActiveStep(activeStep + 1);
    };


    function GetStepContent(step) {
        
        switch (step) {
            case 0:
            return (
              <>
                <div className="_cart_summary_body">
                    <div className="_cart_summary_items">
                        {cartItems && cartItems.length > 0 ? (
                            cartItems.map(item => {
                                const productDetails = getProductDetails(item.productCode);
                                return (
                                    <CartItem key={item.cartItemId} item={item} productDetails={productDetails} />
                                );
                            })
                        ) : (
                            <p>No items in the cart.</p>
                        )}
                    </div>
                    <div className="_cart_summary_divider"></div>
                    <div className="_cart_summary_total_wrapper">
                        <h6 className="_cart_summary_total_title">Cart total</h6>
                        <div>
                            <div className="_cart_summary_total_items">
                                <div className="_cart_summary_total_item">
                                    <span>Subtotal</span>
                                    {totalQuantity > 0 ? (
                                        <span>
                                            ₹{totalCost.toFixed(2)}
                                        </span>
                                    )
                                    :
                                    <span>0</span>
                                    }
                                </div>
                                <div className="_cart_summary_total_item">
                                    <span>Tax</span>
                                    <span>₹0</span>
                                </div>
                                <div className="_cart_summary_total_item">
                                    <span>Delivery</span>
                                    <span>₹0</span>
                                </div>
                            </div>
                            <div>
                                <div className="_cart_summary_total_text">
                                    <span>Total</span>
                                    {totalQuantity > 0 ? (
                                        <span>
                                            ₹{totalCost.toFixed(2)}
                                        </span>
                                    )
                                    :
                                    <span>0</span>
                                    }
                                </div>
                            </div>
                            <button className="_button _is_primary _cart_summary_button" onClick={handleNext}>
                            Proceed to pay
                            </button>
                        </div>
                    </div>
                </div>
              </>
            );
      
            case 1:
            return (
              <>
                <div className="_cart_summary_body _shipping">
                    <ShippingAddForm setActiveStep={setActiveStep} activeStep={activeStep}/>
                    <div className="_cart_summary_body">
                        <div className="_cart_summary_items">
                        {cartItems && cartItems.map(item => (
                            <CartItem item={item} key={item.productCode} qtyBadge={true} tab="shipping"/>
                        ))}
                        </div>
                        <div className="_cart_summary_divider"></div>
                        <div className="_cart_summary_total_wrapper">
                            <h6 className="_cart_summary_total_title">Cart total</h6>
                            <div>
                                <div className="_cart_summary_total_items">
                                    <div className="_cart_summary_total_item">
                                        <span>Subtotal</span>
                                        {totalQuantity > 0 ? (
                                        <span>
                                            ₹{totalCost.toFixed(2)}
                                        </span>
                                            )
                                            :
                                            <span>0</span>
                                            }
                                    </div>
                                    <div className="_cart_summary_total_item">
                                        <span>Tax</span>
                                        <span>0</span>
                                    </div>
                                    <div className="_cart_summary_total_item">
                                        <span>Delivery</span>
                                        <span>0</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="_cart_summary_total_text">
                                        <span>Total</span>
                                        {totalQuantity > 0 ? (
                                        <span>
                                            ₹{totalCost.toFixed(2)}
                                        </span>
                                    )
                                    :
                                    <span>0</span>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </>
            );
            case 2:
            return (
              <>
                <div className="_cart_summary_body _shipping">
                    <CompletePayment setActiveStep={setActiveStep} activeStep={activeStep}/>
                    <div className="_cart_summary_body">
                        <div className="_cart_summary_items">
                        {cartItems && cartItems.map(item => (
                            <CartItem item={item} key={item.productCode} qtyBadge={true} tab="shipping"/>
                        ))}
                        </div>
                        <div className="_cart_summary_divider"></div>
                        <div className="_cart_summary_total_wrapper">
                            <h6 className="_cart_summary_total_title">Cart total</h6>
                            <div>
                                <div className="_cart_summary_total_items">
                                    <div className="_cart_summary_total_item">
                                        <span>Subtotal</span>
                                        {totalQuantity > 0 ? (
                                        <span>
                                            ₹{totalCost.toFixed(2)}
                                        </span>
                                            )
                                            :
                                            <span>0</span>
                                            }
                                    </div>
                                    <div className="_cart_summary_total_item">
                                        <span>Tax</span>
                                        <span>0</span>
                                    </div>
                                    <div className="_cart_summary_total_item">
                                        <span>Delivery</span>
                                        <span>0</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="_cart_summary_total_text">
                                        <span>Total</span>
                                        {totalQuantity > 0 ? (
                                        <span>
                                            ₹{totalCost.toFixed(2)}
                                        </span>
                                    )
                                    :
                                    <span>0</span>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </>
            );
          default:
            return (
                <>

                </>
            );
        }
    }


    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 10,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,rgb(0,91,76) 0%,rgb(0,91,76) 50%,rgb(0,91,76) 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,rgb(0,91,76) 0%,rgb(0,91,76) 50%,rgb(0,91,76) 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 22,
    height: 22,
    display: 'flex',
    fontSize:15,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
        'linear-gradient( 136deg, rgb(0,91,76) 0%, rgb(0,91,76) 50%, rgb(0,91,76) 100%)',
        // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
        'linear-gradient( 136deg, rgb(0,91,76) 0%, rgb(0,91,76) 50%, rgb(0,91,76) 100%)',
    }),
    }));
      
      
    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;
      
        const icons = {
          1: "1",
          2: "2",
          3: "3",
          4: "4",
        };
        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
              {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
          );
    }

    
    return (
        <>
            <section className={`_cart_summary`}>
                <div className="_cart_summary_wrapper _container">
                    <h1 className="_h2 _cart_summary_title">{getTitle() }</h1>
                    {
                        totalQuantity !== 0 ?
                        <div className="_cart_summary_info">
                            <div className="_cart_summary_stepper_nav">
                                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                    {steps.map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>    
                                            <span className={`_cart_summary_head_item ${activeStep === index ? '_cart_summary_active' : ''}`}>
                                                {label}
                                            </span>
                                        </StepLabel>
                                    </Step>
                                    ))}
                                </Stepper>
                            </div>
                            {GetStepContent(activeStep)}
                            {
                                activeStep == 0 ?
                                null
                                :
                                <span className="_cart_summary_back" onClick={() => {setActiveStep(activeStep-1)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                                        <path d="M1.16789 6.77092C0.777369 7.16144 0.777369 7.79461 1.16789 8.18513L7.53185 14.5491C7.92238 14.9396 8.55554 14.9396 8.94607 14.5491C9.33659 14.1586 9.33659 13.5254 8.94607 13.1349L3.28921 7.47803L8.94607 1.82117C9.33659 1.43065 9.33659 0.797484 8.94607 0.40696C8.55554 0.0164351 7.92238 0.0164351 7.53185 0.40696L1.16789 6.77092ZM4.25977 6.47803H1.875V8.47803H4.25977V6.47803Z" fill="#005B4C"/>
                                    </svg>
                                    <span>
                                        Go back
                                    </span>
                                </span>
                            }
                        </div>
                        :
                        <div className="_empty-cart">
                            <svg xmlns="http://www.w3.org/2000/svg" width={376} height={350} viewBox="0 0 376 350" fill="none">
                                <path d="M7.0957 7.46484H32.9482C42.4572 7.46484 50.7783 13.8577 53.2285 23.0457L60.3776 49.8549M105.044 217.354C74.1324 217.354 49.0735 242.413 49.0735 273.325H342.918M105.044 217.354H314.341C335.257 174.431 353.51 129.972 368.884 84.1911C280.046 61.5035 186.957 49.4427 91.0514 49.4427C80.794 49.4427 70.5689 49.5807 60.3776 49.8549M105.044 217.354L60.3776 49.8549M77.0588 329.295C77.0588 337.023 70.7941 343.288 63.0662 343.288C55.3383 343.288 49.0735 337.023 49.0735 329.295C49.0735 321.567 55.3383 315.302 63.0662 315.302C70.7941 315.302 77.0588 321.567 77.0588 329.295ZM314.933 329.295C314.933 337.023 308.669 343.288 300.941 343.288C293.213 343.288 286.948 337.023 286.948 329.295C286.948 321.567 293.213 315.302 300.941 315.302C308.669 315.302 314.933 321.567 314.933 329.295Z" stroke="#005B4C" strokeWidth={13} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <div className="_empty-cart_info">
                                <p>Your cart is empty</p>
                                <Link to="/shop" className="_button _is_primary"> Shop Now</Link>
                            </div>
                        </div>
                    }
                </div>
            </section>
        </>
    );
}
 
export default CartSummary;