import { useEffect, useState } from "react"
import { Tab, TabContent } from "../utilities/tabs/Tab";
import { useSelector } from "react-redux";


const FAQsSection = () => {

    const [visibleTab, setVisibleTab] = useState();
    let faqEntries = useSelector(state => state?.FAQentries?.data?.faqEntries)
    let tabs = useSelector(state => state?.tabDetails?.data?.tabInfo)

    const { data, loading, error } = useSelector(state => state?.tabDetails)

    useEffect(() => {
        if (tabs && tabs.length > 0) {
            setVisibleTab(tabs[0]._tabId);
        }
    }, [data, tabs]);

    if (loading) return (
        <div className="smart-glass">
          <div class="logo">
            <div class="circle">
              <div class="circle">
                <div class="circle">
                </div>
              </div>
            </div>
            <div class="loading-text">
              Loading...
            </div>
          </div>
        </div>
        );
    if (error) return <div>Error: {error}</div>;
    if (!data) return null;

    return (
        <>
            <section className="_faqs-section">
                <div className="_faqs-setion_wrapper _container">
                    <div className="_faqs-setion_head">
                        <ul className="_tab_head">
                            {tabs && tabs.map((tab) => (
                                <Tab
                                    key={tab._tabId}
                                    tab={tab}
                                    visibleTab={visibleTab}
                                    setVisibleTab={setVisibleTab}
                                />
                            ))}
                        </ul>
                    </div>
                    <div className="_faqs-setion_body">
                        <div className="_tab_body">
                            {tabs && tabs.map((tab) => (
                                <TabContent
                                    key={tab._tabId}
                                    tab={tab}
                                    visibleTab={visibleTab}
                                    faqEntries={faqEntries}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default FAQsSection;