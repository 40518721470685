import { TOKEN_FAILURE, TOKEN_REQUEST , TOKEN_SUCCESS } from "./actionTypes";

export const tokenRequest = () => ({
    type: TOKEN_REQUEST,
});
  
export const tokenSuccess = (data) => ({
    type: TOKEN_SUCCESS,
    payload: data,
});
  
export const tokenFailure = (error) => ({
    type: TOKEN_FAILURE,
    payload: error,
});