const MediaCard = ({img, page = undefined}) => {
    return (
        <>
            <div className={`_media_card ${page == undefined ? '' : page }`}>
                <img src={`${img}`}/>
                {/* <span>{typo}</span> */}
            </div>
        </>
    );
}
 
export default MediaCard;