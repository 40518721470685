// src/YouTubeVideo.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const YouTubeVideo = ({ videoId, title, thumbnail }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const videoSrc = `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`;
  const defaultThumbnail ={thumbnail};
//   const defaultThumbnail = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  return (
    <div className="youtube-video _iframe">
      {isPlaying ? (
        <iframe
          width="100%"
          height="100%"
          src={videoSrc}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture"
          allowFullScreen
          title={title}
        ></iframe>
      ) : (
        <div
          className="thumbnail _iframe"
          style={{
            cursor: 'pointer',
            position: 'relative'
          }}
          onClick={() => setIsPlaying(true)}
        >
          <img
            src={thumbnail || defaultThumbnail}
            alt="Video Thumbnail"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '2rem',
              color: 'white',
              textShadow: '0 0 10px rgba(0, 0, 0, 0.5)'
            }}
          >
            {/* ▶️ */}
          </div>
        </div>
      )}
    </div>
  );
};

export default YouTubeVideo;
