import { TOKEN_FAILURE, TOKEN_REQUEST, TOKEN_SUCCESS } from "../actions/actionTypes";

const authToken = (state = { data: null, loading: false, error: null }, action) => {
    switch (action.type) {
      case TOKEN_REQUEST:
        return { ...state, loading: true };
      case TOKEN_SUCCESS:
        return { ...state, loading: false, data: action.payload, error: null };
      case TOKEN_FAILURE:
        return { ...state, loading: false, data: null, error: action.payload };
      default:
        return state;
    }
};

export default authToken;