import React from 'react';


const FilledStar = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <path d="M17.1523 0.303345L20.8568 11.7046H32.8448L23.1463 18.7509L26.8508 30.1521L17.1523 23.1058L7.45389 30.1521L11.1584 18.7509L1.45991 11.7046H13.4479L17.1523 0.303345Z" fill="#005B4C"/>
    </svg>
  );
  
  // SVG for an unfilled star
  const UnfilledStar = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <path d="M17.1523 0.303345L20.8568 11.7046H32.8448L23.1463 18.7509L26.8508 30.1521L17.1523 23.1058L7.45389 30.1521L11.1584 18.7509L1.45991 11.7046H13.4479L17.1523 0.303345Z" fill="#BAA989"/>
    </svg>
  );
  
  // Component to render stars based on rating
  const StarRating = ({ rating }) => {
    // Create an array of 5 elements and fill it based on the rating
    const stars = Array.from({ length: 5 }, (_, index) => {
      return index < rating ? <FilledStar key={index} /> : <UnfilledStar key={index} />;
    });
  
    return <div className="_testimonial_card_rating">{stars}</div>;
  };

  export default StarRating;