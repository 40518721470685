import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const userToken = useSelector(state => state.token?.data?.token);

    const checkUserToken = () => {

        if (!userToken || userToken === 'undefined' || userToken == null) {
          setIsLoggedIn(false);
          return navigate('/auth/login');
        }
        else {
          setIsLoggedIn(true);
        }
    }

    useEffect(() => {
            checkUserToken();
    }, [isLoggedIn]);

    return (
        <>
            {
                isLoggedIn ? props.children : null
            }
        </>
    );
}
export default ProtectedRoute;