import { useEffect, useState } from "react";
import Hero from "../components/sections/Hero";
import ProductGrid from "../components/sections/ProductGrid";
import WhySnoreSection from "../components/sections/WhySnoreSection";
import ProductCarousel from "../components/sections/ProductCarousel";

const WhySnore = () => {
    // Width Body Calculate
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    let contentA = {
        page : "whysnore", 
        title: "Shop Products",
    }

    return (
        <>
            <Hero page={'whysnore'} title={`Why you snore?`} desc={`Lets Explore the Science Behind the Solutions`}/>
            <WhySnoreSection/>

            {
                windowWidth > 700 ?
                    <ProductGrid/>
                :
                    <ProductCarousel {...contentA}/>
            }
        </>
    );
}
 
export default WhySnore;