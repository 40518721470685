import { useEffect, useState } from "react";
import BrandLogo from "../components/utilities/BrandLogo";
import Modal from "../components/utilities/Modal";
import LoginForm from "../components/LoginForm";
import NewUserForm from "../components/NewUserForm";
import { Tab, TabContent } from "../components/utilities/tabs/Tab";

const Login = () => {
    const [changePass, setChangePass] = useState(false);

    // Modal ===========================================
    const [isModalActive, setIsModalActive] = useState(false);
    const [modalContent, setModalContent] = useState({
        modalClass: ''
    })  
    const openModal = (modalClass) => {
        setModalContent({ modalClass });
        setIsModalActive(true);
    };
  
    const closeModal = () => {
        setModalContent({ modalClass: ''});
        setIsModalActive(false);
    };
    // ==================================================


    // Submit form ======================================
    const handleSubmit = (e) => {
        e.preventDefault();
    
        const form = e.currentTarget; // Get the form element
        const formName = form.getAttribute('name'); // Get the form name
        
        switch (formName) {
            case 'login':
                console.log('Login form submitted');
                
                break;
            case 'forgotPassword':
                console.log('Forgot Password form submitted');
                setChangePass(true)
                break;

            case 'changePass':
                console.log('change Password form submitted');
                break;
        
            default:
                break;
        }
    };
    // ==================================================

    // For Tabs =========================================
    const [visibleTab, setVisibleTab] = useState();

    let tabsData = [
        {
            "_id":1,
            "el": <LoginForm openModal={openModal}/>,
            "csName":"_login_exist_responsive",
            "associatedTabs":[1]
        },
        {
            "_id":2,
            "el": <NewUserForm/>,
            "csName":"_login_new_responsive",
            "associatedTabs":[2]
        }
    ]
    
    let tabs = [
        {
            "_tabId":1,
            "tabName":"Login",
        },
        {
            "_tabId":2,
            "tabName":"Sign up",
        }
    ]

    useEffect(() => {
        if (tabs && tabs.length > 0) {
            setVisibleTab(tabs[0]._tabId);
        }
    }, []);
    // ==================================================


    return (
        <>
            <section className='_login'>
                <div className='_login_wrapper _container'>
                    <h1 className="_login_title">Join the SnoreQuit family</h1>
                    <div className="_login_body">
                        <LoginForm openModal={openModal} handleSubmit={handleSubmit}/>
                        <NewUserForm/>
                    </div>
                </div>
                <div className='_login_wrapper _container _login_responsive'>                    
                    <div className="_login_head">
                        <ul className="_tab_head">
                            {tabs && tabs.map((tab) => (
                                <Tab
                                    key={tab._tabId}
                                    tab={tab}
                                    visibleTab={visibleTab}
                                    setVisibleTab={setVisibleTab}
                                />
                            ))}
                        </ul>
                    </div>
                    <div className="_login_body">
                        <div className="_tab_body">
                            {tabs && tabs.map((tab) => (
                                <TabContent
                                    key={tab._tabId}
                                    tab={tab}
                                    visibleTab={visibleTab}
                                    el={tabsData}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <Modal
                    isActive={isModalActive} 
                    closeModal={closeModal} 
                    modalClass={modalContent.modalClass}
                >
                    {
                        changePass ?
                        <div className="_forgot_pass">
                            <div className="_forgot_pass_info">
                                <h6 className="_forgot_pass_title _sub_h2">
                                    Change Password
                                </h6>
                                <p className="_forgot_pass_text">
                                    Set new password
                                </p>
                            </div>
                            <form className="_forgot_pass_form_items" name="changePass" onSubmit={handleSubmit}>
                                <div>
                                    <input
                                        placeholder="New Password"
                                        // value={email}
                                        name="email"
                                    // onChange={handleChange}
                                    // className={errors.email ? '_input_errors' : ''}
                                    />
                                </div>
                                <div>
                                    <input
                                        placeholder="Confirm new Password"
                                        // value={name}
                                        name="name"
                                    // onChange={handleChange}
                                    // className={errors.name ? '_input_errors' : ''}
                                    />
                                </div>
                                <button className="_button _is_primary _forgot_pass_form_button">
                                    Submit
                                </button>
                            </form>
                            <BrandLogo csName="_forgot_pass_logo" fill="#005B4C" w={148} h={60}/>
                        </div>
                        :
                        <div className="_forgot_pass">
                            <div className="_forgot_pass_info">
                                <h6 className="_forgot_pass_title _sub_h2">
                                    Forgot password
                                </h6>
                                <p className="_forgot_pass_text">
                                    Enter your registered phone number
                                </p>
                            </div>
                            <form className="_forgot_pass_form_items" name="forgotPassword" onSubmit={handleSubmit}>
                                <div>
                                    <input
                                        placeholder="Phone number"
                                        // value={email}
                                        name="email"
                                    // onChange={handleChange}
                                    // className={errors.email ? '_input_errors' : ''}
                                    />
                                </div>
                                <div>
                                    <input
                                        placeholder="OTP"
                                        // value={name}
                                        name="name"
                                    // onChange={handleChange}
                                    // className={errors.name ? '_input_errors' : ''}
                                    />
                                </div>
                                <button className="_button _is_primary _forgot_pass_form_button">
                                    Submit
                                </button>
                            </form>
                            <BrandLogo csName="_forgot_pass_logo" fill="#005B4C" w={148} h={60}/>
                        </div>
                    }
                </Modal>
            </section>
        </>
    );
}
 
export default Login;