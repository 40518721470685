import { useRef } from "react";
import ProductSupportOverview from "../ProductSupportOverview";



const ProductSupportSection = () => {
    let faq = [
        {
            _id:1,
            title:'Tips to get started',
            media:'faq.png',
            faq:[
                {
                    qus:'1. Define your Expectation:',
                    ans:'In some cases, snoring stops right away. For others, it could take several days or weeks to resolve. Both situations are standard and justify the duration needed for restful, snore-free sleep.'
                },
                {
                    qus:'2. Give it a try while you are still awake.',
                    ans:'Wear your mouthguard for short, 15-minute spans. Each day, progressively extend this time until you are comfortable using it during sleep.'
                },
                {
                    qus:'3. Build up to all-night usage.',
                    ans:'Adaptation requires time. Don’t be concerned if you are unable to wear it all night, or if you need to skip it for a night or two.'
                },
                {
                    qus:'4. Understand the adaptation procedure.',
                    ans:'You’re developing new muscles, which may lead to jaw or tooth discomfort. Such experiences are common, as are drooling or the mouthpiece coming out—your brain and body are adjusting to it. Everything improves over time.',
                    footerTitle:'You can do it! Believe in yourself!'
                }
            ]
        },
        {
            _id:2,
            title:'',
            media:'product-card_01.png',
            faq:[
                {
                    qus:'Sizing Chart for Mouthpieces',
                    ans:'Our mouthguard is adaptable and conforms to nearly every mouth size. The two available sizes vary in the degree of jaw advancement they offer. Begin with Size 1, and switch to Size 2 only if Size 1 fails to eliminate your snoring.',
                    media:'product-card_01.png'
                }
            ],
            sizes:[
                {
                    title:'Size 1 - Minimal jaw advancement (+2 mm)',
                    text:' Advised for individuals with:',
                    points:['Overbite (lower front teeth positioned behind upper front teeth)','Regular bite alignment', 'Mild to moderate snoring.']
                },
                {
                    title:'Size 2 - Greater jaw advancement (+6 mm):',
                    text:' Advised for individuals with:',
                    points:['Underbite (lower front teeth sit in front of upper front teeth)', 'Regular bite alignment', 'Heavy snoring.']
                }
            ]
        },
        {
            _id:3,
            title:'Mouthguard Maintenance and Renewal',
            media:'faq.png',
            faq:[
                {
                    qus:'Steps to Clean Your SnoreQuiet Mouthguard:',
                    ans:'Extend the lifespan of your mouthpiece by cleaning it each day and placing it flat in its designated storage case.',
                    ansList:[
                        'Effortlessly cleanse your mouthpiece in moments using SnoreQuit Clean, which is specifically designed for your device.',
                        'Alternatively, you can use a toothbrush to gently scrub the mouthpiece using toothpaste or a gentle soap, followed by rinsing with warm water.',
                        'Avoid using alcohol, strong chemicals, bleach, or ammonia for cleaning.',
                        'Keep your mouthpiece in its case to preserve the functionality of the hinge and protect the device.'
                    ],
                    footerDesc:'Keep your mouthpiece in its case to preserve the functionality of the hinge and protect the device.'
                },
                {
                    qus:'When to Renew Your Mouthpiece:',
                    ans:'When used daily, a SnoreQuit mouthpiece ought to be replaced every four months to ensure peak performance and cleanliness. If used less frequently, it should be renewed every six months.'
                }
            ]
        }
    ]


    const sectionRefs = useRef([]);

  
    const scrollToSection = (index) => {
        sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    };



    const handleDownload = (url, filename) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
  

    return (
        <>
            <section className="_product-support">
                <div className="_product-support_wrapper _container">
                    <div className="_product-support_head">
                        <h1 className="_product-support_title _h2">
                            SnoreQuit Product Support
                        </h1>
                        <ul className="_product-support_nav">
                            <li className="_product-support_nav_item" onClick={() => scrollToSection(0)}>
                            Tips to get started
                            </li>
                            <li className="_product-support_nav_item" onClick={() => scrollToSection(1)}>
                            Mouthpiece sizing guide
                            </li>
                            <li className="_product-support_nav_item" onClick={() => scrollToSection(2)}>
                            Care & Replacement
                            </li>
                            <li className="_product-support_nav_item" onClick={() => scrollToSection(4)}>
                            Free Tips for better sleep
                            </li>
                        </ul>
                    </div>
                    <div className="_product-support_body">
                        {faq && faq.map((section, index) => (
                            <div className="_product-support-overview" ref={ref => sectionRefs.current[index] = ref} key={index}>
                                <ProductSupportOverview content={section}/>
                            </div>
                        ))}
                    </div>
                    <div className="_product-support_footer_section" ref={ref => sectionRefs.current[4] = ref}>
                        <h6 className="_product-support_footer_section_title _sub_h2">
                            Product Guides & Instructions
                            <p className="_product-support_footer_section_typo">Download the original instructions for your product</p>
                        </h6>
                        <div className="_product-support_footer_section_buttons">
                            <button onClick={() => handleDownload('https://sqspapps3.s3.ap-south-1.amazonaws.com/assets/docs/product-guide-and-Instructions/2-Pack-Instruction-Manual.pdf', '2-Pack-Instruction-Manual.pdf')} className="_product-support_footer_section_button _button _is_medium _is_primary">2 Pack Instruction Manual</button>
                            <button onClick={() => handleDownload('https://sqspapps3.s3.ap-south-1.amazonaws.com/assets/docs/product-guide-and-Instructions/2-Pack-Instruction-Manual.pdf', '2-Pack-Instruction-Manual.pdf')}  className="_product-support_footer_section_button _button _is_medium _is_primary">SnoreQuit Breathe Manual</button>
                            <button onClick={() => handleDownload('https://sqspapps3.s3.ap-south-1.amazonaws.com/assets/docs/product-guide-and-Instructions/2-Pack-Instruction-Manual.pdf', '2-Pack-Instruction-Manual.pdf')} className="_product-support_footer_section_button _button _is_medium _is_primary">Single Mouthpiece Manual</button>
                            <button onClick={() => handleDownload('https://sqspapps3.s3.ap-south-1.amazonaws.com/assets/docs/product-guide-and-Instructions/2-Pack-Instruction-Manual.pdf', '2-Pack-Instruction-Manual.pdf')} className="_product-support_footer_section_button _button _is_medium _is_primary">SnoreQuit Earplugs Manual</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default ProductSupportSection;