// src/components/Cart.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CartItem from './CartItem';

const Cart = () => {
  const [cart, setCart] = useState(null);
  const [userId, setUserId] = useState('');
  const [status, setStatus] = useState('pending');
  const [productCode, setproductCode] = useState('');
  const [productName, setProductName] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);

  const fetchCart = async (cartId) => {
    const response = await axios.get(`http://localhost:8081/cart/${cartId}`);
    setCart(response.data);
  };

  const createCart = async () => {
    const response = await axios.post('http://localhost:8081/cart', { userId, status });
    fetchCart(response.data.cartId);
  };

  const addItemToCart = async (cartId) => {
    await axios.post(`http://localhost:8081/cart/${cartId}/items`, { productCode, productName, quantity, price });
    fetchCart(cartId);
  };

  useEffect(() => {
    // Fetch the cart if you have a cartId stored
    const cartId = localStorage.getItem('cartId');
    if (cartId) {
      fetchCart(cartId);
    }
  }, []);

  return (
    <div>
      <h1>Cart</h1>
      {!cart ? (
        <div>
          <input placeholder="User ID" value={userId} onChange={(e) => setUserId(e.target.value)} />
          <button onClick={createCart}>Create Cart</button>
        </div>
      ) : (
        <div>
          <h2>Cart ID: {cart[0].cartId}</h2>
          <ul>
            {cart.map(item => (
              <CartItem key={item.cartItemId} item={item} fetchCart={fetchCart} />
            ))}
          </ul>
          <div>
            <input placeholder="Product ID" value={productCode} onChange={(e) => setproductCode(e.target.value)} />
            <input placeholder="Product Name" value={productName} onChange={(e) => setProductName(e.target.value)} />
            <input placeholder="Quantity" type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
            <input placeholder="Price" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
            <button onClick={() => addItemToCart(cart[0].cartId)}>Add Item</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
