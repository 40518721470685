const FAQFeature = () => {
    return (
        <>
            <section className="_faq-feature">
                <div className="_faq-feature_wrapper _container">
                    <h1 className="_faq-feature_heading _sub_h1">Frequently Asked Questions</h1>
                    <p className="_faq-feature_desc">
                    Frequently Asked Questions (FAQs) concerning various elements of your customer journey such as order assistance, shipping methods, returns, and billing processes. If the information you seek is not available, the company's customer support team excels at managing inquiries related to these subjects comprehensively. Customers can depend on the skilled and knowledgeable customer support representatives at SnoreQuit to address their concerns swiftly and effectively. Whether it pertains to questions about the ordering process, tracking deliveries, initiating return requests, or resolving billing issues, the customer support team is always prepared to offer valuable help to ensure a satisfactory experience for each customer.
                    </p>
                </div>
            </section>
        </>
    );
}
 
export default FAQFeature;