import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import baseAPI from '../../API/baseAPI';
import endPoints from '../../API/endPoints';
import { tokenFailure, tokenSuccess } from '../../redux/actions/AuthToken';
import { useSelector, useDispatch } from 'react-redux';

const ScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation();

  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const userData = useSelector(state => state.token?.data);
  // const userToken = userData !== null ? userData?.token : null


  // const checkUserToken = async () => {
  //   try {
  //     const response = await baseAPI.get(`/${endPoints.AUTH.checkTokenExpiration}`, {
  //       headers: { Authorization: userToken }
  //     });
  //   } catch (error) {
  //     dispatch(tokenFailure(error.response.data));
  //     navigate('/auth/login');
  //   }
  // }

  useEffect(() => {
    // checkUserToken();
    
    const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 100)
  }, [pathname]);

  return;
};

export default ScrollToTopOnRouteChange;
